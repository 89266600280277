import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./assets/css/scss/globals.scss"; // Global

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Moment
Vue.use(require("vue-moment"));

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vuexy Admin Filters
import "./filters/filters";

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

// Go Back Directive (v-back)
Vue.directive("back", {
    bind(el) {
        el.addEventListener("click", e => {
            e.preventDefault();
            router.go(-1);
        });
    }
});

Vue.filter("floor", function(value) {
    if (!value) return "";
    value = Math.floor(value);
    return value
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')