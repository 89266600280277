<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table ref="table" v-model="selected" :data="tickets">
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ADD NEW -->
          <AddNewTicket />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="title">title</vs-th>
        <vs-th sort-key="periority">Department</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="created_at">Created At</vs-th>
        <!-- <vs-th sort-key="updated_at">Updated At</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="title font-medium truncate">{{ tr.title }}</p>
            </vs-td>

            <vs-td>
              <p class="title font-medium">{{ tr.department | title }}</p>
            </vs-td>

            <vs-td>
              <vs-chip
                :color="getOrderStatusColor(tr.status)"
                class="product-status"
                >{{ tr.status | title }}</vs-chip
              >
            </vs-td>

            <vs-td>
              <p class="font-medium">
                {{ tr.created_at | moment("MMMM Do YYYY") }}
              </p>
            </vs-td>

            <!-- <vs-td>
              <p class="font-medium">
                {{ tr.updated_at | moment("MMMM Do YYYY") }}
              </p>
            </vs-td> -->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-pagination
      :total="lastPage"
      v-model="page"
      v-if="lastPage > 1"
    ></vs-pagination>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddNewTicket from "./AddNewTicket.vue";

export default {
  components: {
    AddNewTicket,
  },
  data() {
    return {
      page: 1,
      selected: [],
      status: "",
      // Data Sidebar
      addNewTicketSidebar: false,
    };
  },
  computed: {
    ...mapGetters({
      tickets: "ticket/tickets",
      lastPage: "ticket/lastPage",
      departments: "ticket/departments",
    }),
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getTickets: "ticket/getTickets",
      getDepartments: "ticket/getDepartments",
    }),
    //
    addNewData() {
      this.toggleNewTicketSidebar(true);
    },
    deleteData(id) {
      this.$store.dispatch("dataList/removeItem", id).catch((err) => {
        console.error(err);
      });
    },
    getPeriorityColor(periority) {
      if (periority === "low") return "success";
      if (periority === "medium") return "warning";
      if (periority === "high") return "danger";
      return "primary";
    },
    getPeriorityPercent(periority) {
      if (periority === "low") return 20;
      if (periority === "medium") return 50;
      if (periority === "high") return 90;
      return 50;
    },
    getOrderStatusColor(status) {
      if (status === "progress") return "warning";
      if (status === "open") return "success";
      if (status === "close") return "danger";
      return "success";
    },
    toggleNewTicketSidebar(val = false) {
      this.addNewTicketSidebar = val;
    },
  },
  watch: {
    selected() {
      let id = this.selected.id;
      this.$router.push({
        name: "ticket-detail",
        params: { id },
      });
      // this.$router.push({ name: "chat", params: { id } });
    },
    page() {
      this.getTickets({
        page: this.page,
      });
    },
  },
  mounted() {
    this.getTickets();
    this.getDepartments();
  },
};
</script>

<style lang="scss">
@import "./support.scss";
</style>
