<template>
  <vs-prompt
    class="news-prompt"
    title="News"
    buttons-hidden
    :active.sync="newsPrompt"
  >
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in newsData" :key="index">
        <h4 class="text-center mb-3 text-primary" v-if="item.title">{{ item.title }}</h4>
        <h6 class="text-center mb-5 text-primary" v-if="item.short_description">{{ item.short_description }}</h6>
        <div class="content-holder">
          <div class="video-container" v-if="item.video">
          <video controls>
            <source :src="newsStorage(item.video)" type="video/mp4" width="100%" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="image-container" v-if="item.image">
          <img :src="newsStorage(item.image)" :alt="item.title" class="responsive" />
        </div>

        <div class="body-container text-primary" v-html="item.body"></div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </vs-prompt>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import { mapActions } from "vuex";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      newsPrompt: false,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoHeight: true,
        spaceBetween: 30,
      },

      newsData: [],
    };
  },

  methods: {
    ...mapActions({
      getNewsInfo: "notification/getNewsInfo",
    }),

    newsStorage(address) {
      const {origin} = window.location
      return `${origin}${address}`
    },

    async get_news_data() {
      await this.getNewsInfo().then((res) => {
        if (res) {
          if (res.length) {
            this.newsData = res;

            this.newsPrompt = true;
          }
        }
      });
    },
  },

  mounted() {
    this.get_news_data();
  },
};
</script>
