<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card class="overflow-hidden h-full">
    <div slot="no-body" class="h-full">
      <!-- Back Start -->
      <feather-icon
        v-if="parent"
        icon="ArrowLeftIcon"
        class="p-2 md:p-3 inline-flex rounded-full mb-4 back-icon"
        :class="`text-${color}`"
        :style="{ background: `rgba(var(--vs-${color}),.15)` }"
        @click="back"
      ></feather-icon>
      <!-- Back End -->
      <router-link
        v-if="item"
        :to="{ name: 'networkDetail', params: { id: item.user_id } }"
      >
        <div class="p-2 md:p-3 lg:p-3 xl:p-4 text-center">
          <feather-icon
            icon="UserIcon"
            class="p-3 inline-flex rounded-full mb-4"
            :class="`text-${color}`"
            :style="{ background: `rgba(var(--vs-${color}),.15)` }"
          ></feather-icon>
          <div class="content">
            <p class="wallet truncate">
              {{ item.address }}
            </p>
            <p>
              <span>Total Invesment :</span>
              <span>{{ item.total_invest }}</span>
            </p>
            <p>
              <span>Balance Left :</span>
              <span>{{ item.balance_left }}</span>
            </p>
            <p>
              <span>Balance Right :</span>
              <span>{{ item.balance_right }}</span>
            </p>

            <p>
              <span>Created In :</span>
              <span>{{ item.created_at | moment("YYYY - MM - DD") }}</span>
            </p>
          </div>
        </div>
      </router-link>
      <div class="content text-center h-full p-2 md:p-3 lg:p-3 xl:p-4" v-else>
        <feather-icon
          icon="UserIcon"
          class="p-3 inline-flex rounded-full mb-4"
          :class="`text-${color}`"
          :style="{ background: `rgba(var(--vs-${color}),.15)` }"
        ></feather-icon>
        <p class="block">Noting To Show</p>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    parent: {
      type: String,
    },
    item: [Object, Boolean],
    chartData: {
      // type: Array,
      // required: true
    },
    color: {
      type: String,
      default: "primary",
    },
    colorTo: {
      type: String,
    },
    // chartType: {
    //     type: String,
    //     default: 'line-chart',
    // },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    hideChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  methods: {
    back() {
      if (this.parent != null) {
        this.$router.push({
          name: "networkDetail",
          params: { id: this.parent },
        });
      }
    },
  },
};
</script>
