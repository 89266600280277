<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div
      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
      @click.stop="reinvestSidebar = !reinvestSidebar"
    >
      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
      <span class="ml-2 text-base text-primary">Re Invesment</span>
    </div>

    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="reinvestSidebar"
    >
      <!-- <div class="outer" :class="isSidebarActive ? 'd-block' : 'd-none'"></div> -->
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>Re Invesment</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="reinvestSidebar = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div class="p-6">
          <p class="trx-value text-primary">
            Available MATIC Balance :
            <span :class="maticValue < 0.01 && 'text-danger'">{{
              maticValue
            }}</span>
          </p>

          <!-- Select -->
          <div class="vx-row invest-select">
            <div class="vx-col w-full">
              <label for="reason">App</label>
              <select id="reason" v-model="app">
                <option
                  v-for="(item, index) in appsList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <!-- USDT -->
          <div class="vx-row re-incest usdt">
            <div class="vx-col w-full">
              <vs-input-number
                :min="minAmountUSDT"
                v-model="USDT.amount"
                :disabled="USDT.paid"
                color="success"
              />
            </div>
            <div class="vx-col w-full">
              <vs-button
                id="USDT-loading-invest"
                class="w-full action vs-con-loading__container"
                @click="payUSDT"
                v-if="USDT.paid === false || USDT.approveLoading"
                :color="+usdtBalance >= +USDT.amount ? 'success' : 'danger'"
                :disabled="+usdtBalance < +USDT.amount || USDT.approveLoading"
              >
                <p v-if="+usdtBalance >= +USDT.amount">
                  {{ USDT.approveLoading ? "Approving" : "Invest" }} USDT
                </p>
                <p v-else>Not enough balance</p>
                <p>Balance : {{ usdtBalance }}</p>
              </vs-button>
              <vs-button
                disabled
                v-else
                class="w-full action"
                color="success"
                icon-pack="feather"
                icon="icon-check"
              ></vs-button>
            </div>
            <div class="vx-col w-full">
              <table class="w-full">
                <tbody>
                  <tr class="text-success">
                    <td class="w-1/3">
                      Min USDT : {{ investInfo.minUsdtAmount }}
                    </td>
                    <td class="w-1/3 text-center">
                      USDT Fee : {{ investInfo.burnedUsdtAmount }}
                    </td>
                    <td class="w-1/3 text-right">
                      Total :
                      {{
                        parseInt(investInfo.minUsdtAmount) +
                        parseInt(investInfo.burnedUsdtAmount)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- ECS -->
          <div class="vx-row re-incest ecs">
            <div class="vx-col w-full">
              <vs-input-number
                :min="minAmountECS"
                v-model="ECS.amount"
                :disabled="ECS.paid"
                color="primary"
              />
            </div>
            <div class="vx-col w-full">
              <vs-button
                id="ECS-loading-invest"
                class="w-full action vs-con-loading__container"
                @click="payECS"
                v-if="ECS.paid === false || ECS.approveLoading"
                :color="+ecsBalance >= +ECS.amount ? 'primary' : 'danger'"
                :disabled="+ecsBalance < +ECS.amount || ECS.approveLoading"
              >
                <p v-if="+ecsBalance >= +ECS.amount">
                  {{ ECS.approveLoading ? "Approving" : "Invest" }} ECG
                </p>
                <p v-else>Not enough balance</p>
                <p>Balance : {{ ecsBalance }}</p>
              </vs-button>
              <vs-button
                disabled
                v-else
                class="w-full action"
                color="success"
                icon-pack="feather"
                icon="icon-check"
              ></vs-button>
            </div>

            <div class="vx-col w-full">
              <table class="w-full">
                <tbody>
                  <tr class="text-primary">
                    <td class="w-1/3">
                      Min ECG : {{ investInfo.minTokenAmount }}
                    </td>
                    <td class="w-1/3 text-center">
                      ECG Fee : {{ investInfo.burnedTokenAmount }}
                    </td>
                    <td class="w-1/3 text-right">
                      Total :
                      {{
                        parseInt(investInfo.minTokenAmount) +
                        parseInt(investInfo.burnedTokenAmount)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('item-usdtAmount')"
          >
            {{ errors.first("item-usdtAmount") }}
          </span>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button
          class="mr-6"
          @click="reInvestRequest"
          :disabled="!USDT.paid || !ECS.paid"
        >
          Invest
        </vs-button>
        <vs-button
          type="border"
          color="danger"
          @click.stop="reinvestSidebar = false"
        >
          Cancel
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { callContractMixin } from "@/mixins/callCotractMixin";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      app: 1,
      reinvestSidebar: false,
      maticValue: null,
      ECS: {
        approveLoading: false,
        interval: null,
        amount: 0,
        paid: false,
      },
      USDT: {
        approveLoading: false,
        interval: null,
        amount: 0,
        paid: false,
      },
      usdtBalance: 0,
      ecsBalance: 0,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      user: "auth/user",
      //
      appsList: "modulePayment/appsList",
      investInfo: "modulePayment/investInfo",
    }),
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },

    minAmountUSDT() {
      let min = parseInt(this.investInfo.minUsdtAmount);
      let fee = parseInt(this.investInfo.burnedUsdtAmount);
      let total = min + fee;
      return total;
    },

    minAmountECS() {
      let min = parseInt(this.investInfo.minTokenAmount);
      let fee = parseInt(this.investInfo.burnedTokenAmount);
      let total = min + fee;
      return total;
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getInvests: "modulePayment/getInvests",
      getInvestInfo: "modulePayment/getInvestInfo",
      pay: "modulePayment/pay",
    }),
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsBalance = res;
          this.ecsBalance = this.fromSun(ecsBalance);
        },
        false
      );
    },
    //

    // Get USDT Balance Of
    async getUSDTBalanceOf() {
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let usdtBalance = res;
          this.usdtBalance = this.fromSun(usdtBalance);
        },
        false
      );
    },
    //

    // Pay USDT
    async payUSDT(approve) {
      let usdt = this.toSun(this.USDT.amount);
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "allowance",
        [this.connectedAccount, this.contractInfo.contractAddress],
        (res) => {
          if (res >= +usdt) {
            this.stopUSDTInterval(true);
          } else {
            if (approve) {
              this.callContract(
                this.contractInfo.usdtContractAddress,
                "approve",
                [this.contractInfo.contractAddress, usdt],
                (res) => {
                  if (res) {
                    this.startUSDTInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    startUSDTInterval() {
      this.USDT.approveLoading = true;
      this.$vs.loading({
        container: "#USDT-loading-invest",
        scale: 0.45,
      });
      this.USDT.interval = setInterval(() => {
        this.payUSDT(false);
      }, 10000);
      setTimeout(() => {
        this.stopUSDTInterval(false);
      }, 300000);
    },

    stopUSDTInterval(status) {
      this.USDT.approveLoading = false;
      this.$vs.loading.close("#USDT-loading-invest > .con-vs-loading");
      this.USDT.paid = status;

      clearInterval(this.USDT.interval);
    },

    // Pay ECS
    async payECS(approve) {
      let ecs = this.toSun(parseInt(this.ECS.amount));
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.connectedAccount, this.contractInfo.contractAddress],
        (res) => {
          if (res >= +ecs) {
            this.stopECSInterval(true);
          } else {
            if (approve) {
              this.callContract(
                this.contractInfo.ecsContractAddress,
                "approve",
                [this.contractInfo.contractAddress, ecs],
                (res) => {
                  if (res) {
                    this.startECSInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    startECSInterval() {
      this.ECS.approveLoading = true;
      this.$vs.loading({
        container: "#ECS-loading-invest",
        scale: 0.45,
      });
      this.ECS.interval = setInterval(() => {
        this.payECS(false);
      }, 10000);
      setTimeout(() => {
        this.stopECSInterval(false);
      }, 300000);
    },

    stopECSInterval(status) {
      this.ECS.approveLoading = false;
      this.$vs.loading.close("#ECS-loading-invest > .con-vs-loading");
      this.ECS.paid = status;

      clearInterval(this.ECS.interval);
    },

    // Re Invest
    reInvestRequest() {
      let payParam = {
        appId: this.app,
        userId: this.user.id,
        orderId: 0,
        usdt: this.USDT.amount,
        token: this.ECS.amount,
      };
      this.pay(payParam).then((res) => {
        if (res) {
          this.initValues();
          this.getBalance();
          this.reinvestSidebar = !this.reinvestSidebar;
          this.getInvests();
          this.$emit("startInvestInterval");

          // Notify
          this.$vs.notify({
            title: "Done",
            text: "Request Done",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        }
      });
    },
    //

    initValues() {
      this.ECS = {
        amount: "",
        paid: false,
      };
      this.USDT = {
        amount: "",
        paid: false,
      };
    },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.payUSDT();
        }
      });
    },

    // Get USDT & ECS Balance
    async getBalance() {
      this.getECSBalanceOf();
      this.getUSDTBalanceOf();

      // Get TRX Balance
      this.maticValue = await this.getMaticBalance(this.connectedAccount);
      //

      // Set Min Inevesments For ECS & USDT
      let minUsdt = parseInt(this.investInfo.minUsdtAmount);
      let feeUsdt = parseInt(this.investInfo.burnedUsdtAmount);

      let minEcs = parseInt(this.investInfo.minTokenAmount);
      let feeEcs = parseInt(this.investInfo.burnedTokenAmount);

      this.USDT.amount = minUsdt + feeUsdt;

      this.ECS.amount = minEcs + feeEcs;
    },
    //
  },
  async mounted() {
    await this.getInvestInfo({
      appId: 1,
      userId: this.user.id,
    });
    this.getBalance();

    //
  },

  watch: {
    // check inputs for balance enough
    USDT: {
      handler() {
        //
        let inputUSDT = parseInt(this.USDT.amount);
        let userUSDT = parseInt(this.usdtBalance);
        //
        if (inputUSDT >= userUSDT) {
          this.usdtHaveBalance = false;
        } else {
          this.usdtHaveBalance = true;
        }
      },
      deep: true,
    },
    ECS: {
      handler() {
        //
        let inputECS = parseInt(this.ECS.amount);
        let userECS = parseInt(this.ecsBalance);
        //
        if (inputECS >= userECS) {
          this.ecsHaveBalance = false;
        } else {
          this.ecsHaveBalance = true;
        }
      },
      deep: true,
    },
    //
    connectedAccount() {
      if (this.connectedAccount) {
        this.getBalance();
      }
    },
    //
  },
  beforeDestroy() {
    if (this.reinvestSidebar) {
      this.reinvestSidebar = false;
      clearInterval(this.USDT.interval);
      clearInterval(this.ECS.interval);
    }
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.img-preview {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>
