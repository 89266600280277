/*=========================================================================================
  File Name: tronBotMutations.js
  Description: Tron Bot Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
==========================================================================================*/

export default {
    setMetaMaskInstalled(state, payload) {
        state.metaMaskInstalled = payload;
    },

    setConnectedWalletAddress(state, payload) {
        state.connectedAccount = payload;
        sessionStorage.setItem('connectedAccount', payload)
    },
};