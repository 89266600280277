import { mapMutations } from "vuex";

export const metaMaskDetect = {
  data() {
    return {
      poligon: {
        chainId: `0x${Number(137).toString(16)}`, // A 0x-prefixed hexadecimal string
        chainName: "Polygon Mainnet",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: [
          "https://polygon-rpc.com/",
          "https://rpc-mainnet.matic.network",
          "https://matic-mainnet.chainstacklabs.com",
          "https://rpc-mainnet.maticvigil.com",
          "https://rpc-mainnet.matic.quiknode.pro",
          "https://matic-mainnet-full-rpc.bwarelabs.com",
        ],
        blockExplorerUrls: ["https://polygonscan.com"],
      },
    };
  },
  methods: {
    ...mapMutations({
      setMetaMaskInstalled: "metaMask/setMetaMaskInstalled",
      setConnectedWalletAddress: "metaMask/setConnectedWalletAddress",
      LOGOUT: "auth/LOGOUT",
    }),

    /**
     * Switch To Poligon Network
     */
    async swithToPoligon() {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(137).toString(16)}` }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [this.poligon],
            });
          } catch (addError) {
            this.$vs.notify({
              title: "Error",
              text: addError.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              fixed: true,
            });
          }
        }
        // handle other "switch" errors
        this.$vs.notify({
          title: "Error",
          text: switchError.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          fixed: true,
        });
      }
    },
  },
  async mounted() {
    const prodiver = await window.ethereum;

    if (typeof prodiver !== "undefined") {
      this.setMetaMaskInstalled(true);

      /**
       * Try Connect To MetaMast
       */
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          this.setConnectedWalletAddress(accounts[0]);

          this.swithToPoligon();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            fixed: true,
          });
        });

      /**
       * Check If MetaMast Disconnect
       */
      window.ethereum.on("disconnect", () => {
        this.LOGOUT();
        this.$router.push({ name: "login" });
      });

      /**
       * Check If MetaMast Account Change
       */
      window.ethereum.on("accountsChanged", (accounts) => {
        this.setConnectedWalletAddress(accounts[0]);
        this.LOGOUT();
        this.$router.push({ name: "login" });
      });

      /**
       * Check If Chane Change
       */
      window.ethereum.on("chainChanged", (chainId) => {
        if (chainId != "0x89") {
          // Handle the new chain.
          // Correctly handling chain changes can be complicated.
          // We recommend reloading the page unless you have good reason not to.
          window.location.reload();
        }
      });
    } else {
      this.setMetaMaskInstalled(false);
    }
  },
};
