import api from "../../axios";

export default {
    async login({ commit }, payload) {
        try {
            const { data } = await api.post("login", payload);

            //
            const { auth, user } = data.result;

            //
            commit("SET_AUTH", auth);
            commit("SET_USER", user);
            commit("SET_TOKEN", auth.access_token);
            //

            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("token", auth.access_token);

            if (user.two_fa_url) {
                return user.two_fa_url;
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async saveTwoFa(_, payload) {
        try {
            await api.post("site/save-two-fa-key", payload);

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    async register(_, payload) {
        try {
            await api.post("site/register", payload);

            //
            return true;
        } catch (error) {
            return false;
        }
    },

    async getDashboardData({ commit }) {
        try {
            const { data } = await api.get("dashboard");

            //
            let dashboard = data.result;
            //

            //
            commit("SET_DASHBOARD_DATA", dashboard);
            //
            return true;
        } catch (error) {
            return false;
        }
    },

    async getThirdPartyToken(_, payload) {
        try {
            const { data } = await api.get("user/third-party-login", {
                params: {
                    id: payload,
                },
            });

            //
            let url = data.result;

            //
            return url;
        } catch (error) {
            return false;
        }
    },

    async checkUserExist(_, payload) {
        try {
            const { data } = await api.get('user/exists', {
                params: {
                    address: payload
                }
            });

            if (data.result === -1) {
                return false
            }
            return true
        } catch (error) {
            return false
        }
    }
};