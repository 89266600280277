<template>
  <div>
    <FindNFTs @addNFT="getTokenUrlById" />
    <div class="items-grid-view vx-row match-height">
      <p
        class="not-data-table vs-table--not-data w-full"
        v-if="!nftList.length"
      >
        No NFT Available
      </p>

      <div
        class="vx-col lg:w-1/3 sm:w-1/2 w-full"
        v-for="item in nftList"
        :key="item.id"
      >
        <GridItem :item="item" @removeFromList="removeFromList" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Moralis from "moralis";

import { mapActions, mapGetters } from "vuex";

import { callContractMixin } from "@/mixins/callCotractMixin";

import GridItem from "../../../components/nfts/GridItem.vue";
import FindNFTs from "./FindNFTs.vue";
export default {
  mixins: [callContractMixin],
  components: { GridItem, FindNFTs },
  data() {
    return {
      nftList: [],
      loading: false,
    };
  },

  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
    }),
    //
  },

  methods: {
    ...mapActions({
      setNotification: "notification/setNotification",
      showNotification: "notification/showNotification",
    }),
    removeFromList(id) {
      this.nftList = this.nftList.filter((item) => item.nft_id != id);
    },

    /**
     * Checking the number of user NTFs
     */
    getNfts() {
      let contracts = this.contractInfo.availableNfts;

      for (let i = 0; i < contracts.length; i++) {
        const contract = contracts[i];

        if (contract.name === "Virtualand") {
          this.getNFTsWithMoralis(contract);
        } else {
          this.getNFTsWithContract(contract);
        }
      }
    },

    /**
     * Get NTFs With Contract
     */
    async getNFTsWithContract(contract) {
      this.$vs.loading();

      await this.callContract(
        contract.address,
        "balanceOf",
        [this.connectedAccount],
        async (res) => {
          console.log("contract", res);
          let balanceCount = parseInt(res);
          if (balanceCount <= 0) {
            this.$vs.loading.close();
          } else {
            await this.getDetailById(contract, balanceCount);
          }
        },
        false
      );
    },

    /**
     * Get NFTs With Moralis
     */
    async getNFTsWithMoralis(contract) {
      const serverUrl = "https://tps5x5defrri.usemoralis.com:2053/server";
      const appId = "c99iNEMJfpms2GBle4yIQxEvm6Q32ssHA2K8jsW9";
      const options = {
        chain: "MATIC",
        address: this.connectedAccount,
      };

      //
      Moralis.start({ serverUrl, appId });

      //
      const nfts = await Moralis.Web3.getNFTs(options);
      console.log("nfts", nfts);
      nfts.forEach((element) => {
        if (element.name === "Virtualand") {
          this.getTokenUrlById(contract, element.token_id);
        }
      });
    },

    /**
     * Get Specific id of each NTF
     */
    async getDetailById(contract, index) {
      const helperArray = [];
      for (let i = 0; i < index; i++) {
        const element = i;
        helperArray.push(element);
      }
      helperArray.forEach(async (element) => {
        await this.callContract(
          contract.address,
          "tokenOfOwnerByIndex",
          [this.connectedAccount, element],
          (res) => {
            let id = parseInt(res);
            this.getTokenUrlById(contract, id);
          },
          false
        );
      });
    },

    /**
     * Get NFT info with id
     */
    async getTokenUrlById(contract, id) {
      let pushedBefore = false;
      for (let i = 0; i < this.nftList.length; i++) {
        const nft = this.nftList[i];
        if (nft.nft_source_id === contract.id && nft.nft_id == id) {
          pushedBefore = true;
        }
      }

      if (!pushedBefore) {
        this.$vs.loading();
        await axios
          .get(`${contract.url_base}${id}`)
          .then((res) => {
            this.nftList.push({
              ...res.data,
              nft_id: id,
              nft_source_id: contract.id,
              contract: contract.address,
            });
          })
          .catch((err) => {
            this.showNotification();
            this.setNotification({
              title: err.response.starus,
              message: err.response.data.message,
            });

            this.$vs.loading.close();
          });
        this.$vs.loading.close();
      } else {
        this.$vs.notify({
          title: "NFT",
          text: "NFT are already in your list",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          fixed: true,
        });
      }
    },
  },

  mounted() {
    if (this.connectedAccount) {
      this.getNfts();
    }
  },

  watch: {
    connectedAccount() {
      if (this.connectedAccount) {
        this.getNfts();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./nfts.scss";
</style>
