/*=========================================================================================
  File Name: paymentMutations.js
  Description: Payment Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  setInvests(state, responce) {
    return (state.invests = responce);
  },

  setInvestMine(state, responce) {
    return (state.investMine = responce);
  },

  setBenefits(state, responce) {
    return (state.benefits = responce);
  },

  setBenefitsMine(state, responce) {
    return (state.benefitsMine = responce);
  },

  setBenefitsNFT(state, responce) {
    return (state.benefitsNFT = responce);
  },

  setBenefitsNFTMine(state, responce) {
    return (state.benefitsNFTMine = responce);
  },

  setWithdraws(state, responce) {
    return (state.withdraws = responce);
  },

  setWithdrawsMine(state, responce) {
    return (state.withdrawsMine = responce);
  },

  setAvailableBalance(state, responce) {
    return (state.availableBalance = responce);
  },

  setWithdrawsNFT(state, responce) {
    return (state.withdrawsNFT = responce);
  },

  setWithdrawsNFTMine(state, responce) {
    return (state.withdrawsNFTMine = responce);
  },

  setAvailableNFTBalance(state, responce) {
    return (state.availableNFTBalance = responce);
  },

  setInvestInfo(state, responce) {
    return (state.investInfo = responce);
  },

  setAppsList(state, responce) {
    return (state.appsList = responce);
  },
};
