<!-- =========================================================================================
    File Name: Chat.vue
    Description: Chat Application - Stay connected
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="router-header flex flex-wrap items-center mb-6">
      <div
        class="content-area__heading pr-4 border-0 md:border-r border-solid border-grey-light"
      >
        <h2 class="mb-1">Ticket - {{ selectedTicket.id }}</h2>
      </div>
      <div class="vx-breadcrumb ml-4 md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a href="#/" class="router-link-active">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-home h-5 w-5 mb-1 stroke-current text-primary"
                >
                  <path
                    d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                  ></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
              </span>
            </a>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline>
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <router-link :to="{ name: 'ticket' }" class="text-primary">
              Tickets
            </router-link>
            <span class="breadcrumb-separator mx-2 flex items-start">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg
              ></span>
            </span>
          </li>
          <li class="inline-flex">
            <span class="cursor-default">Ticket Info</span>
          </li>
        </ul>
      </div>
    </div>
    <div id="chat-app" class="rounded relative overflow-hidden">
      <!-- RIGHT COLUMN -->
      <div class="chat__bg no-scroll-content chat-content-area">
        <div class="chat__navbar">
          <ChatNavbar />
        </div>
        <component
          :is="scrollbarTag"
          class="chat-content-scroll-area"
          :settings="settings"
          ref="chatLogPS"
          :key="$vs.rtl"
        >
          <div class="chat__log" ref="chatLog">
            <ChatLog />
          </div>
        </component>
        <div class="chat__input flex p-4 bg-white">
          <vs-input
            class="flex-1 mr-2"
            placeholder="Type Your Message"
            v-model="message.message"
            @keyup.enter="sendMsg"
          />
          <div class="upload-img">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="addAtachment"
              multiple
              accept="image/*, .pdf, .zip, .rar"
            />
            <!-- <vs-button
              class="mr-2"
              color="primary"
              type="gradient"
              icon-pack="feather"
              icon="icon-upload-cloud"
              @click="$refs.uploadImgInput.click()"
            ></vs-button> -->
          </div>
          <vs-button
            color="primary"
            type="gradient"
            icon-pack="feather"
            icon="icon-send"
            @click="sendMsg"
          ></vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import ChatLog from "./ChatLog.vue";
import ChatNavbar from "./ChatNavbar.vue";

export default {
  components: {
    VuePerfectScrollbar,
    ChatLog,
    ChatNavbar,
  },
  data() {
    return {
      message: {
        message: "",
        attachment: [],
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
    };
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
  },
  computed: {
    ...mapGetters({
      selectedTicket: "ticket/selectedTicket",
    }),
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    // Veux Map Actions
    ...mapActions({
      sendReply: "ticket/sendReply",
      showTicket: "ticket/showTicket",
    }),
    //

    // Upload Image
    addAtachment(e) {
      let file = e.target.files;
      for (let i = 0; i < file.length; i++) {
        const element = file[i];
        this.message.attachment.push(element);
      }
    },

    // Send Message
    sendMsg() {
      //
      const { message, attachment } = this.message;

      //
      if (message) {
        const fd = new FormData();

        //
        fd.append("message", message);
        if (attachment) {
          attachment.forEach((element) => {
            fd.append("attachments[]", element);
          });
        }

        //
        this.sendReply({
          id: this.$route.params.id,
          content: fd,
        }).then((res) => {
          if (res) {
            this.massage = {
              message: "",
              attachment: [],
            };
          }
        });
      }
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
  },
  beforeDestroy() {
    this.$store.unregisterModule("chat");
  },
  mounted() {
    this.showTicket(this.$route.params.id);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";
</style>
