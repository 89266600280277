<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-exchange"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="
                  require('@/assets/images/coins/ECS (3D) v2 Gold (250x250).png')
                "
                alt="login"
                class="mx-auto"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="px-8 pt-8 exchange-tabs-container">
                <div class="vx-card__title mb-4">
                  <div class="flex">
                    <a v-back class="pointer">
                      <feather-icon
                        icon="ArrowLeftIcon"
                        class="pr-3"
                      ></feather-icon>
                    </a>

                    <h4 class="mb-4">Exchange ECS Token</h4>
                  </div>
                </div>

                <section class="tron-web-message">
                  <span v-if="!metaMaskInstalled">
                    Please Install Meta Mask From
                    <a :href="metaMaskInstallAddress">Here</a>.
                  </span>
                  <span v-if="metaMaskInstalled && !connectedAccount">
                    Please Login into your Meta Mask account!
                  </span>

                  <div class="form text-left" v-if="connectedAccount">
                    <form @submit.prevent="exchange">
                      <p
                        :class="
                          ecsBalance == 0 ? 'text-danger' : 'text-primary'
                        "
                      >
                        Available ECS Gold Balance : {{ ecsGoldBalance }}
                      </p>
                      <div class="vx-row">
                        <div class="vx-col w-full mt-base input-holder">
                          <vs-input
                            label="Amount"
                            v-model="amount"
                            type="number"
                            class="w-full"
                          />
                          <vs-button
                            color="primary"
                            type="filled"
                            size="small"
                            @click="amount = ecsBalance"
                            >MAX</vs-button
                          >
                          <p
                            :class="
                              ecsBalance == 0 ? 'text-danger' : 'text-primary'
                            "
                          >
                            Available ECS Balance : {{ ecsBalance }}
                          </p>
                        </div>
                        <div class="vx-col w-full token-select mt-base">
                          <vs-select
                            label="Change with"
                            v-model="token"
                            color="info"
                            class="w-full"
                            autocomplete
                          >
                            <vs-select-item
                              :key="index"
                              :value="item.value"
                              :text="item.label"
                              v-for="(item, index) in tokens"
                            />
                          </vs-select>
                        </div>

                        <div class="vx-col mt-base calc w-full">
                          <template v-if="amount && token">
                            <p>
                              1 ECS =
                              <span class="text-primary">
                                {{ perToken }} {{ selectedToken.label }}
                              </span>
                            </p>
                            <p>
                              You Will Receive :
                              <span class="text-primary"
                                >{{ receiveCalc }}
                                {{ selectedToken.label }}</span
                              >
                            </p>
                          </template>
                        </div>

                        <div class="vx-col w-full mt-base">
                          <vs-button
                            :disabled="exchangeDisable"
                            color="primary"
                            type="filled"
                            @click="exchange"
                            >Exchange</vs-button
                          >
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { callContractMixin } from "@/mixins/callCotractMixin.js";

export default {
  data() {
    return {
      metaMaskInstallAddress:
        "https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec",

      ecsBalance: "",
      ecsGoldBalance: "",
      amount: "",
      token: "",
      selectedToken: {},
      tokens: [
        {
          label: "ECS Gold",
          value: 1,
        },
      ],
      receiveCalc: "",
      perToken: "",
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      metaMaskInstalled: "metaMask/metaMaskInstalled",
      connectedAccount: "metaMask/connectedAccount",
      metaMaskObject: "metaMask/metaMaskObject",
      //
      contractInfo: "contract/contractInfo",
    }),
    //

    // Exchange Disable
    exchangeDisable() {
      if (
        !this.amount ||
        !this.token ||
        +this.ecsBalance == 0 ||
        +this.amount > +this.ecsBalance
      ) {
        return true;
      }
      return false;
    },
    //
  },
  methods: {
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
    }),

    // Pursue Transaction
    pursueTransaction(hash) {
      this.$vs.notify({
        title: "Persure Transaction",
        text: "Click Here To Persure Transaction",
        color: "primary",
        click: () => {
          window.open(`https://tronscan.org/#/transaction/${hash}`, "_blank");
        },
      });
    },
    //

    // Init Data
    initData() {
      this.amount = "";
      this.token = "";
      this.selectedToken = {};
      this.receiveCalc = "";
    },
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsOldContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsBalance = res;
          this.ecsBalance = this.fromSun(ecsBalance);
        },
        false
      );
    },
    //

    // Get ECS Gold Balance Of
    async getECSGoldBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsGoldBalance = res;
          this.ecsGoldBalance = this.fromSun(ecsGoldBalance);
        },
        false
      );
    },
    //

    async exchange() {
      let ecs = this.toSun(this.amount);
      let ecsContract = this.contractInfo.ecsContractAddress;
      let oldEcsContract = this.contractInfo.ecsOldContractAddress;

      //
      await this.callContract(
        oldEcsContract,
        "approve",
        [ecsContract, ecs],
        (res) => {
          if (res) {
            console.log(res);
            this.callContract(
              ecsContract,
              "exchange",
              [oldEcsContract, ecs],
              (res) => {
                console.log(res);
                if (res) {
                  this.pursueTransaction(res);
                  this.initData();
                  this.getECSGoldBalanceOf();
                  this.getECSBalanceOf();
                }
              },
              true
            );
          }
        },
        true
      );
    },
  },

  async mounted() {
    //
    this.$vs.loading();
    //
    await this.getBaseInfo();
    //
    this.$vs.loading.close();
    //

    if (this.connectedAccount) {
      this.getECSBalanceOf();
      this.getECSGoldBalanceOf();
    }
  },
  watch: {
    // Set Selected Token
    async token() {
      this.tokens.filter((item) => {
        if (item.value === this.token) {
          this.selectedToken = item;
        }
      });

      if (this.token) {
        //
        let ecs = this.toSun(1);
        let ecsContract = this.contractInfo.ecsContractAddress;
        let oldEcsContract = this.contractInfo.ecsOldContractAddress;
        await this.callContract(
          ecsContract,
          "checkExchange",
          [oldEcsContract, ecs],
          (res) => {
            let perToken = res;
            this.perToken = this.fromSun(perToken);
          },
          false
        );
      }
    },
    //

    async amount() {
      let ecs = this.toSun(this.amount);
      let ecsContract = this.contractInfo.ecsContractAddress;
      let oldEcsContract = this.contractInfo.ecsOldContractAddress;

      if (this.amount) {
        await this.callContract(
          ecsContract,
          "checkExchange",
          [oldEcsContract, ecs],
          (res) => {
            let receiveCalc = res;
            this.receiveCalc = this.fromSun(receiveCalc);
          },
          false
        );
      }
    },

    connectedAccount() {
      if (this.connectedAccount) {
        this.getECSBalanceOf();
        this.getECSGoldBalanceOf();
      }
    },
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss">
@import "./exchange.scss";
</style>
