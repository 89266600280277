<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div
      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
      @click.stop="burnSidebar = !burnSidebar"
    >
      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
      <span class="ml-2 text-base text-primary">Burn</span>
    </div>

    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="burnSidebar"
    >
      <!-- <div class="outer" :class="isSidebarActive ? 'd-block' : 'd-none'"></div> -->
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>Burn</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="burnSidebar = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div class="p-6">
          <p class="text-primary">
            Available MATIC Balance :
            <span :class="maticValue < 0.01 && 'text-danger'">{{
              maticValue
            }}</span>
          </p>

          <!-- Select -->
          <div class="vx-row invest-select">
            <div class="vx-col w-full">
              <label for="reason">Burn Reason</label>
              <select id="reason" v-model="reason">
                <option
                  v-for="(item, index) in burnReason"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- USDT -->
          <div class="vx-row re-incest usdt">
            <div class="vx-col w-full">
              <vs-input-number
                v-model="USDT.amount"
                :disabled="USDT.paid"
                color="success"
              />
            </div>
            <div class="vx-col w-full">
              <vs-button
                id="USDT-loading-burn"
                class="w-full action vs-con-loading__container"
                @click="payUSDT"
                v-if="USDT.paid === false || USDT.approveLoading"
                :color="usdtHaveBalance ? 'success' : 'danger'"
                :disabled="!usdtHaveBalance || USDT.approveLoading"
              >
                <p v-if="usdtHaveBalance">
                  {{ USDT.approveLoading ? "Approving" : "Invest" }} USDT
                </p>
                <p v-else>Not enough balance</p>
                <p>Balance : {{ usdtBalance }}</p>
              </vs-button>
              <vs-button
                disabled
                v-else
                class="w-full action"
                color="success"
                icon-pack="feather"
                icon="icon-check"
              ></vs-button>
            </div>
          </div>

          <!-- ECS -->
          <div class="vx-row re-incest ecs">
            <div class="vx-col w-full">
              <vs-input-number
                v-model="ECS.amount"
                :disabled="ECS.paid"
                color="primary"
              />
            </div>
            <div class="vx-col w-full">
              <vs-button
                id="ECS-loading-burn"
                class="w-full action vs-con-loading__container"
                @click="payECS"
                v-if="ECS.paid === false || ECS.approveLoading"
                :color="ecsHaveBalance ? 'primary' : 'danger'"
                :disabled="!ecsHaveBalance || ECS.approveLoading"
              >
                <p v-if="ecsHaveBalance">
                  {{ ECS.approveLoading ? "Approving" : "Invest" }} ECG
                </p>
                <p v-else>Not enough balance</p>
                <p>Balance : {{ ecsBalance }}</p>
              </vs-button>
              <vs-button
                disabled
                v-else
                class="w-full action"
                color="primary"
                icon-pack="feather"
                icon="icon-check"
              ></vs-button>
            </div>
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('item-usdtAmount')"
          >
            {{ errors.first("item-usdtAmount") }}
          </span>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button
          class="mr-6"
          @click="burnRequest"
          :disabled="!USDT.paid || !ECS.paid"
          color="danger"
        >
          Burn
        </vs-button>
        <vs-button
          type="border"
          color="danger"
          @click.stop="burnSidebar = false"
        >
          Cancel
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { callContractMixin } from "@/mixins/callCotractMixin";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      burnSidebar: false,
      maticValue: null,
      reason: 1,

      ECS: {
        approveLoading: false,
        interval: null,
        amount: 0,
        paid: false,
      },
      USDT: {
        approveLoading: false,
        interval: null,
        amount: 0,
        paid: false,
      },
      usdtBalance: "",
      ecsBalance: "",
      burnBtnDisabled: true,
      usdtHaveBalance: false,
      ecsHaveBalance: false,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
      //
      user: "auth/user",
      //
      burnReason: "modulePayment/burnReason",
    }),
    //
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getInvests: "modulePayment/getInvests",
      burn: "modulePayment/burn",
    }),
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsBalance = res;
          this.ecsBalance = this.fromSun(ecsBalance);
        },
        false
      );
    },
    //

    // Get USDT Balance Of
    async getUSDTBalanceOf() {
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let usdtBalance = res;
          this.usdtBalance = this.fromSun(usdtBalance);
        },
        false
      );
    },
    //

    // Pay USDT
    async payUSDT(approve) {
      let usdt = this.toSun(this.USDT.amount);
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "allowance",
        [this.connectedAccount, this.contractInfo.contractAddress],
        (res) => {
          if (res >= +usdt) {
            this.stopUSDTInterval(true);
          } else {
            if (approve) {
              this.callContract(
                this.contractInfo.usdtContractAddress,
                "approve",
                [this.contractInfo.contractAddress, usdt],
                (res) => {
                  if (res) {
                    this.startUSDTInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    startUSDTInterval() {
      this.USDT.approveLoading = true;
      this.$vs.loading({
        container: "#USDT-loading-burn",
        scale: 0.45,
      });
      this.USDT.interval = setInterval(() => {
        this.payUSDT(false);
      }, 10000);

      setTimeout(() => {
        this.stopUSDTInterval(false);
      }, 300000);
    },

    stopUSDTInterval(status) {
      this.USDT.approveLoading = false;
      this.$vs.loading.close("#USDT-loading-burn > .con-vs-loading");
      this.USDT.paid = status;

      clearInterval(this.USDT.interval);
    },

    // Pay ECS
    async payECS(approve) {
      let ecs = this.toSun(parseInt(this.ECS.amount));
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.connectedAccount, this.contractInfo.contractAddress],
        (res) => {
          if (res >= +ecs) {
            this.stopECSInterval(true);
          } else {
            if (approve) {
              this.callContract(
                this.contractInfo.ecsContractAddress,
                "approve",
                [this.contractInfo.contractAddress, ecs],
                (res) => {
                  if (res) {
                    this.startECSInterval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    startECSInterval() {
      this.ECS.approveLoading = true;
      this.$vs.loading({
        container: "#ECS-loading-burn",
        scale: 0.45,
      });
      this.USDT.interval = setInterval(() => {
        this.payECS(false);
      }, 10000);
      setTimeout(() => {
        this.stopECSInterval(false);
      }, 300000);
    },

    stopECSInterval(status) {
      this.ECS.approveLoading = false;
      this.$vs.loading.close("#ECS-loading-burn > .con-vs-loading");
      this.ECS.paid = status;

      clearInterval(this.ECS.interval);
    },

    // Burn Invest
    async burnRequest() {
      let payParam = {
        reason: this.reason,
        userId: this.user.id,
        usdt: this.USDT.amount,
        token: this.ECS.amount,
      };
      this.burn(payParam).then((res) => {
        if (res) {
          this.getUSDTBalanceOf();
          this.getECSBalanceOf();
          this.burnSidebar = !this.burnSidebar;
          this.initValues();
          this.getInvests();

          // Notify
          this.$vs.notify({
            title: "Done",
            text: "Request Done",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        }
      });
    },
    //

    initValues() {
      this.ECS = {
        amount: "",
        inputDisable: false,
        paid: false,
      };
      this.USDT = {
        amount: "",
        inputDisable: false,
        paid: false,
      };
    },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.payUSDT();
        }
      });
    },

    // Check User Balance
    checkUserBalance() {
      // USDT
      let inputUSDT = parseInt(this.USDT.amount);
      let userUSDT = parseInt(this.usdtBalance);

      //
      if (inputUSDT >= userUSDT) {
        this.usdtHaveBalance = false;
      } else {
        this.usdtHaveBalance = true;
      }

      // ECS
      let inputECS = parseInt(this.ECS.amount);
      let userECS = parseInt(this.ecsBalance);

      //
      if (inputECS >= userECS) {
        this.ecsHaveBalance = false;
      } else {
        this.ecsHaveBalance = true;
      }
    },
    //

    // Get USDT & ECS Balance
    async getBalance() {
      this.getECSBalanceOf();
      this.getUSDTBalanceOf();
      // Get TRX Balance
      this.maticValue = await this.getMaticBalance(this.connectedAccount);
      //

      this.checkUserBalance();
    },
    //
  },
  mounted() {
    this.getBalance();
  },
  watch: {
    // check inputs for balance enough
    USDT: {
      handler() {
        //
        let inputUSDT = parseInt(this.USDT.amount);
        let userUSDT = parseInt(this.usdtBalance);
        //
        if (inputUSDT >= userUSDT) {
          this.usdtHaveBalance = false;
        } else {
          this.usdtHaveBalance = true;
        }
      },
      deep: true,
    },
    ECS: {
      handler() {
        //
        let inputECS = parseInt(this.ECS.amount);
        let userECS = parseInt(this.ecsBalance);
        //
        if (inputECS >= userECS) {
          this.ecsHaveBalance = false;
        } else {
          this.ecsHaveBalance = true;
        }
      },
      deep: true,
    },
    //

    //
    connectedAccount() {
      if (this.connectedAccount) {
        this.getBalance();
      }
    },
    //
  },
  beforeDestroy() {
    if (this.burnSidebar) {
      this.burnSidebar = false;
      clearInterval(this.USDT.interval);
      clearInterval(this.ECS.interval);
    }
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.img-preview {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>
