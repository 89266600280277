<!-- =========================================================================================
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="component-chat-log" class="m-8">
    <div
      v-for="(msg, index) in selectedTicket.answers"
      class="msg-grp-container"
      :key="index"
    >
      <div
        class="flex items-start"
        :class="[{ 'flex-row-reverse': msg.user_type != 'client' }]"
      >
        <template v-if="msg.user_type != 'client'">
          <vs-avatar
            size="40px"
            class="border-2 shadow border-solid border-white m-0 flex-shrink-0 sm:ml-5 ml-3"
            :src="require(`@/assets/images/admin-1.jpg`)"
          ></vs-avatar>
        </template>

        <template v-else>
          <vs-avatar
            size="40px"
            class="border-2 shadow border-solid border-white m-0 flex-shrink-0 sm:mr-5 mr-3"
            :src="require(`@/assets/images/admin-2.jpg`)"
          ></vs-avatar>
        </template>

        <div class="message mb-2">
          <div
            class="msg inline-block break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
            :class="{
              'bg-primary-gradient text-white': msg.user_type != 'client',
              'border border-solid border-transparent bg-white':
                msg.user_type == 'client',
            }"
          >
            <p class="time">{{ $moment(msg.created_at).fromNow() }}</p>
            <span>{{ msg.message }}</span>
          </div>

          <div class="file-icon flex">
            <vx-card
              no-body
              :class="{
                'text-center mr-2': true,
                'flex-row-reverse': msg.user_type != 'client',
              }"
              v-for="(file, i) in msg.files"
              :key="i"
            >
              <a :href="file" target="_blank">
                <feather-icon icon="FileIcon" class="ml-1" />
              </a>
            </vx-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      selectedTicket: "ticket/selectedTicket",
    }),
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      });
    },
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>
