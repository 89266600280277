<template>
  <vx-card class="grid-view-item mb-base overflow-hidden">
    <template slot="no-body">
      <!-- ITEM IMAGE -->
      <div
        class="item-img-container bg-white flex items-center justify-center mb-4 cursor-pointer"
      >
        <img :src="item.image" :alt="item.name" class="grid-view-img" />
      </div>
      <div class="item-details px-4">
        <!-- TITLE & DESCRIPTION -->
        <div class="my-4">
          <h6 class="truncate font-semibold mb-2 cursor-pointer">
            {{ item.name }}
          </h6>
          <p class="flex items-center justify-between text-sm">
            ID : <span>{{ item.nft_id }}</span>
          </p>
          <p
            class="flex items-center justify-between text-sm"
            v-for="(value, key, index) in filteredAttr"
            :key="index"
          >
            {{ key }} :
            <span>{{ value }}</span>
          </p>
        </div>
      </div>

      <div class="flex flex-wrap">
        <!-- SECONDARY BUTTON: ADD-TO/VIEW-IN CART -->
        <vs-button
          :id="`freez-loading-${item.nft_id}`"
          @click="openAlert"
          :disabled="approveLoading"
          class="vs-con-loading__container flex flex-grow items-center justify-center text-white rounded-t-none"
        >
          <feather-icon icon="CloudSnowIcon" svgClasses="h-4 w-4" />

          <span class="text-sm font-semibold ml-2"> Freez NFT </span>
        </vs-button>
      </div>
    </template>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { callContractMixin } from "@/mixins/callCotractMixin";
export default {
  mixins: [callContractMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      approveLoading: false,
    };
  },

  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
    }),
    //

    filteredAttr() {
      const asArray = Object.entries(this.item.attributes);

      const filtered = asArray.filter((item) => item[1] != null);

      const asObject = Object.fromEntries(filtered);

      return asObject;
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      freezeNFT: "modulePayment/freezeNFT",
    }),
    //

    openAlert() {
      this.$vs.dialog({
        color: "danger",
        title: "Freeze NFT",
        text: "Are you sure to freez this item ?",
        accept: this.startFreezing,
        acceptText: "Confirm",
      });
    },

    /**
     * Start Freezing
     */
    async startFreezing(approve = true) {
      let id = this.item.nft_id;
      let contract = this.item.contract;
      await this.callContract(
        contract,
        "getApproved",
        [id],
        (res) => {
          console.log("getApproved : ", res);
          if (res == this.contractInfo.contractAddress) {
            this.stopInterval();
            this.freezNFT();
          } else {
            if (approve) {
              this.callContract(
                contract,
                "approve",
                [this.contractInfo.contractAddress, id],
                (res) => {
                  if (res) {
                    this.startIntreval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },

    // Freez
    freezNFT() {
      let params = {
        nftSourceId: this.item.nft_source_id,
        nftId: this.item.nft_id,
      };
      this.freezeNFT(params).then((res) => {
        if (res) {
          this.$emit("removeFromList", this.item.nft_id);
          // Notify
          this.$vs.notify({
            title: "Done",
            text: "Request Done",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        }
      });
    },
    //

    startIntreval() {
      this.approveLoading = true;
      this.$vs.loading({
        container: `#freez-loading-${this.item.nft_id}`,
        scale: 0.45,
      });
      this.interval = setInterval(() => {
        this.startFreezing(false);
      }, 10000);
      setTimeout(() => {
        this.stopInterval();
      }, 300000);
    },

    stopInterval() {
      this.approveLoading = false;
      this.$vs.loading.close(
        `#freez-loading-${this.item.nft_id} > .con-vs-loading`
      );
      clearInterval(this.interval);
    },
  },
};
</script>
