<template>
  <div class="vx-col w-full md:w-1/2 freez md:pr-4">
    <vx-card>
      <img
        :src="require('@/assets/images/freeze ECG (s).jpg')"
        alt="content-img"
        class="responsive rounded-lg"
      />
      <p class="mt-5">
        <span> Your ECG Balance : </span>
        <span :class="ecsBalance > 0 ? 'text-success' : 'text-danger'">
          {{ ecsBalance }}
        </span>
      </p>
      <section>
        <!-- Amount -->
        <div class="input-holder">
          <vs-input
            label="Amount (ECG)"
            v-model="ecsAmount"
            class="mt-5 w-full mb-5"
            name="item-name"
            type="number"
            v-validate="'required'"
          />
          <vs-button
            size="small"
            class="max-ecs"
            @click="maxBalance"
            color="success"
            type="filled"
          >
            Max
          </vs-button>
        </div>
        <div class="flex justify-between flex-wrap">
          <vs-button
          id="freez-loading"
            class="mr-6 vs-con-loading__container"
            @click="submitData"
            :disabled="!isFormValid || approveLoading"
          >
            {{approveLoading ? 'Approving' : 'Freez'}}
          </vs-button>
        </div>
      </section>
    </vx-card>
  </div>
</template>

<script>
import { callContractMixin } from "@/mixins/callCotractMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      approveLoading: false,
      interval: null,

      ecsAmount: "",
      ecsBalance: "",
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
      //
      user: "auth/user",
    }),
    //

    isFormValid() {
      return (
        !this.errors.any() &&
        this.ecsAmount &&
        parseInt(this.ecsAmount) <= parseInt(this.ecsBalance)
      );
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getDashboardData: "auth/getDashboardData",
      freeze: "modulePayment/freeze",
    }),
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsBalance = res;
          this.ecsBalance = this.fromSun(ecsBalance);
        },
        false
      );
    },
    //

    // Pursue Transaction
    pursueTransaction(hash) {
      this.$vs.notify({
        title: "Persure Transaction",
        text: "Click Here To Persure Transaction",
        color: "primary",
        click: () => {
          window.open(`https://tronscan.org/#/transaction/${hash}`, "_blank");
        },
      });
    },
    //

    // Freez
    freezECS(ecs) {
      let payParam = {
        userId: this.user.id,
        token: this.fromSun(ecs),
      };
      this.freeze(payParam).then((res) => {
        if (res) {
          this.getDashboardData();
          this.getECSBalanceOf();
          this.initValues();

          // Notify
          this.$vs.notify({
            title: "Done",
            text: "Request Done",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        }
      });
    },
    //

    // Freez ECS
    async payECS(approve) {
      let ecs = this.toSun(this.ecsAmount);
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.connectedAccount, this.contractInfo.contractAddress],
        (res) => {
          if (res >= +ecs) {
            this.stopInterval();
            this.freezECS(ecs);
          } else {
            if (approve) {
              this.callContract(
                this.contractInfo.ecsContractAddress,
                "approve",
                [this.contractInfo.contractAddress, ecs],
                (res) => {
                  if (res) {
                    this.startIntreval();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    initValues() {
      this.ecsAmount = "";
    },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.payECS(true);
        }
      });
    },

    startIntreval() {
      this.approveLoading = true;
      this.$vs.loading({
        container: "#freez-loading",
        scale: 0.45
      })
      this.interval = setInterval(() => {
        this.payECS(false);
      }, 10000);
      setTimeout(() => {
        this.stopInterval()
      }, 300000);
    },

    stopInterval() {
      this.approveLoading = false;
      this.$vs.loading.close("#freez-loading > .con-vs-loading")
      clearInterval(this.interval);
    },

    maxBalance() {
      this.ecsAmount = this.ecsBalance;
    },
  },
  mounted() {
    this.getECSBalanceOf();
  },
  watch: {
    connectedAccount() {
      if (this.connectedAccount) {
        this.getECSBalanceOf();
      }
    },
  },
  mixins: [callContractMixin],
};
</script>
