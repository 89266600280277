<template>
  <section id="dashboard">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CopyIcon"
          icon-right
          statistic="Referral Link Left"
          :statisticTitle="
            dashboard.isQualified
              ? `${url}/#/register/?referral_code=${connectedAccount}&side=left`
              : hideReferral
          "
          color="dark"
          copyToClipboard
        />
      </div>

      <div class="vx-col w-full md:w-1/2">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CopyIcon"
          icon-right
          statistic="Referral Link Right"
          :statisticTitle="
            dashboard.isQualified
              ? `${url}/#/register/?referral_code=${connectedAccount}&side=right`
              : hideReferral
          "
          color="dark"
          copyToClipboard
        />
      </div>

      <template v-if="dashboard && dashboard.topSellers.length > 0">
        <div
          class="vx-col w-full"
          :class="[
            { 'md:w-1/2': dashboard.topSellers.length == 2 },
            { 'md:w-1/3': dashboard.topSellers.length == 3 },
            { 'md:w-1/4': dashboard.topSellers.length == 4 },
            { 'md:w-1/5': dashboard.topSellers.length == 5 },
          ]"
          v-for="(item, index) in dashboard.topSellers"
          :key="index"
        >
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="AwardIcon"
            :statistic="topSellerTitle(index)"
            :statisticTitle="item.shares"
            :statisticSubject="item.address"
            :color="topSellerColor(index)"
          />
        </div>
      </template>
      <template v-else>
        <div class="vx-col w-full">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="AwardIcon"
            statistic="No Body"
            statisticTitle="0"
            statisticSubject="-"
            color="danger"
          />
        </div>
      </template>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 xl:w-1/2">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ChevronsLeftIcon"
              icon-right
              :statistic="dashboard.myLeftBalance"
              :statisticTitle="`Left Balance (${dashboard.myLeftCount})`"
              color="warning"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ChevronsRightIcon"
              icon-right
              :statistic="dashboard.myRightBalance"
              :statisticTitle="`Right Balance (${dashboard.myRightCount})`"
              color="warning"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="CreditCardIcon"
              icon-right
              :statistic="dashboard.myUsdtInvestment"
              statisticTitle="USDT Invesment"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="CreditCardIcon"
              icon-right
              :statistic="dashboard.myEcsInvestment"
              statisticTitle="ECG Invesment"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              icon-right
              class="mb-base"
              icon="AwardIcon"
              :statistic="dashboard.myDirectSellCommission"
              :statisticTitle="`Direct Sell Commission (${dashboard.myDirectSellCount})`"
              color="success"
            />
          </div>
          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClipboardIcon"
              icon-right
              :statistic="dashboard.myCalculatedBalance"
              statisticTitle="Binary Calculated Balance"
              color="danger"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClipboardIcon"
              icon-right
              :statistic="dashboard.myAmbassadorCalculatedBalance"
              statisticTitle="Ambassador Calculated Balance"
              color="danger"
            />
          </div>

          <div class="vx-col w-full md:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClipboardIcon"
              icon-right
              :statistic="dashboard.myLeadershipCalculatedBalance"
              statisticTitle="Leadership Calculated Balance"
              color="danger"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-full lg:w-1/2 xl:w-1/2 mb-base">
        <CommissionCard />
      </div>
    </div>

    <div class="vx-row">
      <!-- ECS CHART -->
      <EcsPool />

      <!-- TOP LEADER CHART -->
      <TopLeaderPool />

      <!-- Binary CHART -->
      <BinaryPool />

      <!-- Ambassador Chart -->
      <AmbassadorPool />

      <!-- Top Seller Chart -->
      <TopSellerPool />
    </div>
  </section>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import CommissionCard from "@/components/dashboard/CommissionCard.vue";
import EcsPool from "@/components/dashboard/EcsPoll.vue";
import TopLeaderPool from "@/components/dashboard/TopLeaderPool.vue";
import BinaryPool from "@/components/dashboard/BinaryPool.vue";
import AmbassadorPool from "@/components/dashboard/AmbassadorPool.vue";
import TopSellerPool from "@/components/dashboard/TopSellerPool.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    StatisticsCardLine,
    CommissionCard,
    EcsPool,
    TopLeaderPool,
    BinaryPool,
    AmbassadorPool,
    TopSellerPool,
  },
  data() {
    return {
      hideReferral: `You're not qualified enough to show referral link. Minimum 50$ investment required`,
    };
  },
  computed: {
    ...mapGetters({
      dashboard: "auth/dashboard",
      connectedAccount: "metaMask/connectedAccount",
      user: "auth/user",
      contractInfo: "contract/contractInfo",
    }),

    url() {
      return window.location.origin;
    },
  },

  methods: {
    ...mapActions({
      getDashboardData: "auth/getDashboardData",
      getBaseInfo: "contract/getBaseInfo",
    }),

    // Top Seller Title
    topSellerTitle(index) {
      if (index == 0) return "1st";
      if (index == 1) return "2nd";
      if (index == 2) return "3rd";
      if (index == 3) return "4th";
      if (index == 4) return "5th";
    },
    //

    // Top Seller Color
    topSellerColor(index) {
      if (index == 0) return "warning";
      if (index == 1) return "success";
      return "danger";
    },

    // Get Dashboard Data
    async get_Dashboard_Data() {
      // Start Loading
      this.$vs.loading();
      //
      await this.getBaseInfo();
      await this.getDashboardData();
      // Close Loading
      this.$vs.loading.close();
      //
    },
  },
  async mounted() {
    await this.get_Dashboard_Data();
  },
};
</script>

<style lang="scss">
/*! rtl:begin:ignore */
@import "./dashabord.scss";
</style>
