<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-sync"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="
                  require('@/assets/images/coins/ECS (3D) v2 Gold (250x250).png')
                "
                alt="login"
                class="mx-auto"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="px-8 pt-8 sync-tabs-container">
                <!--div class="vx-card__title mb-4">
                  <div class="flex">
                    <a v-back class="pointer">
                      <feather-icon
                        icon="ArrowLeftIcon"
                        class="pr-3"
                      ></feather-icon>
                    </a>

                    <h4 class="mb-4">Sync ECS</h4>
                  </div>
                </div-->

                <section class="tron-web-message">
                  <span v-if="!metaMaskInstalled">
                    Please Install Meta Mask From
                    <a :href="metaMaskInstallAddress">Here</a>.
                  </span>
                  <span v-if="metaMaskInstalled && !connectedAccount">
                    Please Login into your Meta Mask account!
                  </span>

                  <div class="form text-left" v-if="connectedAccount">
                    <p
                      class="mb-base"
                      :class="ecsBalance == 0 ? 'text-danger' : 'text-primary'"
                    >
                      ECS Balance : {{ ecsBalance }}
                    </p>

                    <p
                      class="mb-base"
                      :class="ecsBalance == 0 ? 'text-danger' : 'text-primary'"
                    >
                      Available ECS Balance : {{ availableEcsBalance }}
                    </p>

                    <p
                      class="mb-base"
                      :class="ecsBalance == 0 ? 'text-danger' : 'text-primary'"
                    >
                      ECS Gold Balance : {{ ecsGoldBalance }}
                    </p>
                  </div>
                </section>

                <template v-if="getTronConnectionStatus === true">
                  <!-- <p class="text-primary">ECS Gold price : 0.22 USD</p> -->
                  <vs-button
                    class="action"
                    color="primary"
                    type="filled"
                    @click="sync"
                    >Sync</vs-button
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { callContractMixin } from "@/mixins/callCotractMixin.js";

export default {
  data() {
    return {
      metaMaskInstallAddress:
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",

      ecsBalance: "",
      ecsGoldBalance: "",
      syncPercent: "",
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      metaMaskInstalled: "metaMask/metaMaskInstalled",
      connectedAccount: "metaMask/connectedAccount",
      metaMaskObject: "metaMask/metaMaskObject",
      //
      contractInfo: "contract/contractInfo",
    }),
    //

    // Available ECS Balance
    availableEcsBalance() {
      let ecs = this.ecsBalance;
      let percent = this.syncPercent;

      return (ecs * percent) / 100;
    },
  },
  methods: {
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
    }),

    // Pursue Transaction
    pursueTransaction(hash) {
      this.$vs.notify({
        title: "Persure Transaction",
        text: "Click Here To Persure Transaction",
        color: "primary",
        click: () => {
          window.open(`https://tronscan.org/#/transaction/${hash}`, "_blank");
        },
      });
    },
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsOldContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsBalance = res;
          this.ecsBalance = this.fromSun(ecsBalance);
        },
        false
      );
    },
    //

    // Get ECS Gold Balance Of
    async getECSGoldBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.connectedAccount],
        (res) => {
          let ecsGoldBalance = res;
          this.ecsGoldBalance = this.fromSun(ecsGoldBalance);
        },
        false
      );
    },
    //

    // Get Sync Percent
    async getSyncPercent() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "getSyncPercent",
        [],
        (res) => {
          let syncPercent = res;
          this.syncPercent = this.fromSun(syncPercent);
        },
        false
      );
    },
    //

    async sync() {
      let ecsContract = this.contractInfo.ecsContractAddress;
      //
      await this.callContract(
        ecsContract,
        "syncEcs",
        [],
        (res) => {
          console.log(res);
          this.pursueTransaction(res);
          this.getECSGoldBalanceOf();
          this.getECSBalanceOf();
          this.getSyncPercent();
        },
        true
      );
    },
  },

  async mounted() {
    //
    this.$vs.loading();
    //
    await this.getBaseInfo();
    //
    this.$vs.loading.close();
    //

    if (this.connectedAccount) {
      this.getECSBalanceOf();
      this.getECSGoldBalanceOf();
      this.getSyncPercent();
    }
  },
  watch: {
    connectedAccount() {
      if (this.connectedAccount) {
        this.getECSBalanceOf();
        this.getECSGoldBalanceOf();
        this.getSyncPercent();
      }
    },
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss">
@import "./sync.scss";
</style>
