// /*=========================================================================================
//   File Name: ticketActions.js
//   Description: Ticket Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/

import api from "../../axios";

export default {
    // Get Network
    async getNetwork({ commit }) {
        try {
            const { data } = await api.get("tree");

            //
            let network = data.result;
            //

            //
            commit("SET_NETWORK", network);
            //
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    //

    // Get Network Detail
    async getNetworkDetail({ commit }, payload) {
        try {
            const { data } = await api.get(`tree/${payload}`);

            //
            let network = data.result;
            //

            //
            commit("SET_NETWORK", network);
            //
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    //
};