<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ArrowDownCircleIcon"
          icon-right
          :statistic="`${contractInfo.withdrawUsdtFeePercent}%`"
          statisticTitle="Withdraw Fee"
          color="danger"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="AlertCircleIcon"
          icon-right
          :statistic="dashboard.myBurnedBenefit"
          statisticTitle="Burned"
          color="danger"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          withdrawBtn
          class="mb-base"
          icon="CreditCardIcon"
          icon-right
          :statistic="benefitsMine"
          statisticTitle="Your Benefits"
        />
      </div>
    </div>

    <vs-table ref="table" :data="benefits">
      <template slot="thead">
        <vs-th sort-key="type">type</vs-th>
        <vs-th sort-key="amount">amount</vs-th>
        <vs-th sort-key="time">time</vs-th>
        <vs-th sort-key="description">description</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            @click="goToChat(tr.id)"
          >
            <vs-td>
              <p class="font-medium" :class="tr.type">
                {{ benefitType(tr.type) }}
              </p>
            </vs-td>

            <vs-td>
              <p class="font-medium">{{ tr.amount }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium">
                {{ tr.created_at | moment("MMMM Do YYYY") }}
              </p>
            </vs-td>

            <vs-td class="description">
              <vx-tooltip
                :text="tr.description"
                position="bottom"
                v-if="tr.type != 'thirdPartyCommission'"
              >
                <p class="description font-medium truncate">
                  {{ tr.description }}
                </p>
              </vx-tooltip>
              <ThirdPartyDescription :description="tr.description" v-else />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <vs-pagination :total="10" v-model="page"></vs-pagination> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import ThirdPartyDescription from "./ThirdPartyDescription.vue";

export default {
  components: {
    StatisticsCardLine,
    ThirdPartyDescription
  },
  data() {
    return {
      page: 4,
      selected: [],

      // Data Sidebar
      ReInvesment: false
    };
  },
  computed: {
    ...mapGetters({
      dashboard: "auth/dashboard",
      benefits: "modulePayment/benefits",
      benefitsMine: "modulePayment/benefitsMine",
      contractInfo: "contract/contractInfo"
    })
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getBenefits: "modulePayment/getBenefits",
      getDashboardData: "auth/getDashboardData"
    }),
    //

    benefitType(type) {
      if (type === "fun") return "Fun";
      if (type === "binary") return "Binary";
      if (type === "overflow") return "Overflow";
      if (type === "ambassador") return "Ambassador";
      if (type === "leadership") return "Leadership";
      if (type === "directSell") return "Direct Sell";
      if (type === "top_sellers") return "Top Sellers";
      if (type === "ecs_share_holders") return "ECS Share Holders";
      if (type === "thirdPartyCommission") return "Third Party Commission";
      if (type === "nft_share_holders") return "NFT Share Holders";
      if (type === "nft") return "NFT";
    }
  },
  async mounted() {
    // Start Loading
    this.$vs.loading();
    //
    await this.getBaseInfo();
    await this.getDashboardData();
    await this.getBenefits();
    // Close Loading
    this.$vs.loading.close();
    //
  }
};
</script>

<style lang="scss">
@import "./payment.scss";
</style>
