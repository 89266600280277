/*=========================================================================================
  File Name: paymentState.js
  Description: Payment State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    invests: [],
    investMine: "",
    benefits: [],
    benefitsMine: "",
    benefitsNFT: [],
    benefitsNFTMine: "",
    withdraws: [],
    withdrawsMine: "",
    availableBalance: "",
    withdrawsNFT: [],
    withdrawsNFTMine: "",
    availableNFTBalance: "",
    investInfo: {},
    burnReason: [{
            value: 1,
            label: "Qualified leadership fee",
        },
        {
            value: 2,
            label: "Qualified Ambassador fee",
        },
        {
            value: 0,
            label: "other",
        },
    ],

    appsList: [],
};