<template>
  <vx-card class="list-view-item overflow-hidden">
    <template slot="no-body">
      <div class="vx-row item-details no-gutter">
        <!-- IMG COL -->
        <div
          class="vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center cursor-pointer"
        >
          <img
            :src="item.data.image"
            :alt="item.data.name"
            class="grid-view-img"
          />
        </div>

        <!-- ITEM NAME & DESC COL -->
        <div class="vx-col sm:w-1/2 w-full">
          <div class="p-4 pt-6">
            <slot name="item-meta">
              <h6
                class="item-name font-semibold mb-4 hover:text-primary cursor-pointer"
              >
                {{ item.data.name }}
              </h6>
              <p
                class="flex items-center justify-between text-sm"
                v-for="(value, key, index) in filteredAttr"
                :key="index"
              >
                {{ key }} : <span>{{ value }}</span>
              </p>
            </slot>
          </div>
        </div>

        <!-- PURCHASE COL -->
        <div
          class="vx-col sm:w-1/4 w-full flex items-center sm:border-2 border-0 border-solid d-theme-border-grey-light sm:border-r-0 sm:border-t-0 sm:border-b-0"
        >
          <div class="p-4 flex flex-col w-full">
            <div class="my-6">
              <h5 class="font-bold text-center">ID : {{ item.nft_id }}</h5>
            </div>

            <vs-button
              @click="openAlert"
              :id="`unfreez-loading-${item.id}`"
              :disabled="loading"
              class="vs-con-loading__container rounded-lg flex flex-grow items-center justify-center text-white"
            >
              <feather-icon icon="SunIcon" svgClasses="h-4 w-4" />

              <span class="text-sm font-semibold ml-2"> Unfreez NFT </span>
            </vs-button>
          </div>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    filteredAttr() {
      const asArray = Object.entries(this.item.data.attributes);

      const filtered = asArray.filter((item) => item[1] != null);

      const asObject = Object.fromEntries(filtered);

      return asObject;
    },
  },
  methods: {
    ...mapActions({
      unfreezNFT: "modulePayment/unfreezNFT",
    }),

    openAlert() {
      this.$vs.dialog({
        color: "danger",
        title: "UnFreeze NFT",
        text: "Are you sure to unfreez this item ?",
        accept: this.startUNFreezing,
        acceptText: "Confirm",
      });
    },

    /**
     * Start Unfreezing
     */
    async startUNFreezing() {
      this.loading = true;
      this.$vs.loading({
        container: `#unfreez-loading-${this.item.id}`,
        scale: 0.45,
      });

      //
      await this.unfreezNFT(this.item.id).then((res) => {
        if (res) {
          this.$emit("removeFromList", this.item.id);
          this.$vs.notify({
            title: "un freez",
            text: "un freez Request Send",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
        }
      });

      //
      this.loading = false;
      this.$vs.loading.close(
        `#unfreez-loading-${this.item.id} > .con-vs-loading`
      );
    },
  },
};
</script>
