/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAuth from "./auth/moduleAuth.js";
import tronBot from "./tronBot/moduleTronBot.js";
import metaMask from "./metaMask/moduleMetaMask.js";
import contract from "./contractAddress/contract.js";
import ticket from "./ticket/ticket.js";
import modulePayment from "./payment/modulePayment.js";
import moduleNetwork from "./network/networkModule.js";
import notification from "./notification/netificationModule.js";
import faq from "./faq/faq.js";

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        tronBot,
        metaMask,
        contract,
        ticket,
        modulePayment,
        moduleNetwork,
        notification,
        faq,
    },
    strict: process.env.NODE_ENV !== "production",
});