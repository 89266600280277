var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-8",attrs:{"id":"component-chat-log"}},_vm._l((_vm.selectedTicket.answers),function(msg,index){return _c('div',{key:index,staticClass:"msg-grp-container"},[_c('div',{staticClass:"flex items-start",class:[{ 'flex-row-reverse': msg.user_type != 'client' }]},[(msg.user_type != 'client')?[_c('vs-avatar',{staticClass:"border-2 shadow border-solid border-white m-0 flex-shrink-0 sm:ml-5 ml-3",attrs:{"size":"40px","src":require("@/assets/images/admin-1.jpg")}})]:[_c('vs-avatar',{staticClass:"border-2 shadow border-solid border-white m-0 flex-shrink-0 sm:mr-5 mr-3",attrs:{"size":"40px","src":require("@/assets/images/admin-2.jpg")}})],_c('div',{staticClass:"message mb-2"},[_c('div',{staticClass:"msg inline-block break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm",class:{
            'bg-primary-gradient text-white': msg.user_type != 'client',
            'border border-solid border-transparent bg-white':
              msg.user_type == 'client',
          }},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.$moment(msg.created_at).fromNow()))]),_c('span',[_vm._v(_vm._s(msg.message))])]),_c('div',{staticClass:"file-icon flex"},_vm._l((msg.files),function(file,i){return _c('vx-card',{key:i,class:{
              'text-center mr-2': true,
              'flex-row-reverse': msg.user_type != 'client',
            },attrs:{"no-body":""}},[_c('a',{attrs:{"href":file,"target":"_blank"}},[_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"FileIcon"}})],1)])}),1)])],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }