<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="require('@/assets/images/pages/ancestry-login.jpg')"
                alt="login"
                class="mx-auto authorize-logo"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title mb-8">
                  <h4>Login</h4>

                  <div class="otherlink-box">
                    <a
                      :href="tronVersionLink"
                      class="exchange-link text-primary mb-3"
                    >
                      Tron Version
                      <feather-icon icon="ArrowRightIcon"></feather-icon>
                    </a>
                  </div>
                </div>

                <section class="tron-web-message" v-if="step === 1">
                  <span v-if="!metaMaskInstalled">
                    Please Install Meta Mask From
                    <a :href="metaMaskInstallAddress">Here</a>.
                  </span>
                  <span v-if="metaMaskInstalled && !connectedAccount">
                    Please Login into your Meta Mask account!
                  </span>
                  <div v-if="connectedAccount" class="text-center">
                    <p class="mb-base">Successfully connected to your Wallet</p>
                    <p class="wallet-address">
                      {{ connectedAccount }}
                    </p>
                    <form @submit.prevent="loginUser" class="twofa">
                      <vs-input
                        placeholder="2fa code"
                        v-model="code"
                        class="w-full"
                      />
                      <div class="actions">
                        <vs-button
                          color="primary"
                          type="filled"
                          size="small"
                          @click="loginUser"
                        >
                          Login
                        </vs-button>

                        <vs-button
                          color="danger"
                          type="border"
                          size="small "
                          @click="loginUser"
                        >
                          Dont Have
                        </vs-button>
                      </div>
                    </form>
                  </div>
                </section>

                <section class="qr-code-box" v-if="step === 2">
                  <img :src="qrCode" alt="qrCode" />
                  <div class="twofa-key pt-5">
                    <p>{{ user.two_fa_key }}</p>
                    <feather-icon
                      icon="CopyIcon"
                      class="text-primary"
                      @click="copy(user.two_fa_key)"
                    ></feather-icon>
                  </div>
                  <form @submit.prevent="save_twofa" class="twofa">
                    <vs-input
                      placeholder="2fa code"
                      v-model="twoFaCode.code"
                      class="w-full"
                    />
                    <div class="actions">
                      <vs-button
                        color="primary"
                        type="filled"
                        size="small"
                        @click="save_twofa"
                        >Login</vs-button
                      >
                    </div>
                  </form>
                </section>

                <vs-divider color="primary" class="google-divider">
                  <router-link :to="{ name: 'google-help' }">
                    Google Authentication Help
                  </router-link>
                </vs-divider>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <!-- <a
        href="https://ecogamecenter.net/"
        target="_blank"
        rel="noopener noreferrer"
        class="flex mt-4"
      >
        <vx-card
          title="Attention"
          title-color="#fff"
          card-background="warning"
          content-color="#fff"
        >
          <p class="mb-0">Eco Gamecenter is comming soon</p>
        </vx-card>
      </a> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      step: 1,
      qrCode: "",
      metaMaskInstallAddress:
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",

      // Step 1
      token: "",
      code: "",

      // Step 2
      twoFaCode: {
        code: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      metaMaskInstalled: "metaMask/metaMaskInstalled",
      connectedAccount: "metaMask/connectedAccount",
      metaMaskObject: "metaMask/metaMaskObject",
      //
      contractInfo: "contract/contractInfo",
      //
      dashboard: "auth/dashboard",
      //
      user: "auth/user",
    }),

    tronVersionLink() {
      return process.env.VUE_APP_TRON_VERSION_LINK;
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getDashboardData: "auth/getDashboardData",
      login: "auth/login",
      saveTwoFa: "auth/saveTwoFa",
      getBaseInfo: "contract/getBaseInfo",
      pay: "modulePayment/pay",
    }),
    //

    // Redirect To Dashboard
    redirectDashboard() {
      this.$vs.notify({
        title: "You Are Loged In",
        text: "Redirecting To Dashboard Page",
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });

      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
    //

    // Get Token
    async loginUser() {
      // Start Loading
      this.$vs.loading();
      //

      this.login({
        wallet: this.connectedAccount,
        code: this.code,
      }).then((res) => {
        //
        if (res) {
          // Close Loading
          this.$vs.loading.close();

          if (typeof res === "boolean") {
            this.redirectDashboard();
          } else {
            this.qrCode = res;
            this.step = 2;
          }
        } else {
          // Close Loading
          this.$vs.loading.close();
        }
      });
    },
    //

    // Save 2fa
    async save_twofa() {
      // Start Loading
      this.$vs.loading();
      //

      this.saveTwoFa(this.twoFaCode).then((res) => {
        //
        if (res) {
          this.redirectDashboard();
          // Get Dashboard Data
          // this.getDashboardData().then(res => {
          //   if (res === true) {
          //     this.payRequest();
          //   }
          // });
        } else {
          // Close Loading
          this.$vs.loading.close();
        }
      });
    },
    //
  },
  async mounted() {
    // Get Base Info
    await this.getBaseInfo();
    //
  },
};
</script>

<style lang="scss">
@import "./login.scss";
</style>
