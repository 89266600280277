/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule =>editorh user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

import authMiddleware from "../utils/authMiddleware";

Vue.use(Router);

const router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "",
            component: () =>
                import ("../layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: "/",
                    redirect: "/dashboard",
                },
                {
                    path: "/dashboard",
                    name: "dashboard",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/dashboard/Dashboard.vue"),
                },
                {
                    path: "/payment/invesments",
                    name: "Invesment",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/payment/Invesments.vue"),
                },
                {
                    path: "/payment/benefits",
                    name: "Benefits",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/payment/Benefits.vue"),
                },
                {
                    path: "/payment/staking-reward",
                    name: "BenefitsNFT",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/payment/StakingReward.vue"),
                },
                {
                    path: "/freeze-ecg",
                    name: "FreezeECG",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/freeze-ecs/FreezeECS.vue"),
                },
                {
                    path: "/freezed-nfts",
                    name: "freezed-nfts",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/nfts/FreezedNFTs.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "NTFs" },
                            { title: "Freezed NFTs", active: true },
                        ],
                        pageTitle: "Freezed NFTs",
                        rule: "editor",
                    },
                },
                {
                    path: "/nfts",
                    name: "nfts",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/nfts/NFTs.vue"),
                    meta: {
                        breadcrumb: [
                            { title: "Home", url: "/" },
                            { title: "NTFs" },
                            { title: "My NFTs", active: true },
                        ],
                        pageTitle: "My NFTs",
                        rule: "editor",
                    },
                },
                {
                    path: "/withdraws/commissions",
                    name: "Withdraws",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/withdraws/Commission.vue"),
                },
                {
                    path: "/withdraws/nft",
                    name: "Withdraws-nft",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/withdraws/NFT.vue"),
                },
                {
                    path: "/network",
                    name: "network",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/network/index.vue"),
                },
                {
                    path: "/network/:id",
                    name: "networkDetail",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/network/NetworkDetail.vue"),
                },
                {
                    path: "/guideline",
                    name: "guideline",
                    beforeEnter: authMiddleware,
                    component: () =>
                        import ("../views/apps/supoort/SupportCenter.vue"),
                },
                {
                    path: "/ticket",
                    name: "ticket",
                    component: () =>
                        import ("../views/apps/chat/Support.vue"),
                    meta: {
                        rule: "editor",
                    },
                },
                {
                    path: "/ticket/:id",
                    name: "ticket-detail",
                    component: () =>
                        import ("../views/apps/chat/Chat.vue"),
                    meta: {
                        rule: "editor",
                    },
                },
                {
                    path: "/faqs",
                    name: "FAQs",
                    component: () =>
                        import ("../views/apps/faq/FAQ.vue"),
                },
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () =>
                import ("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: "/login",
                    name: "login",
                    component: () =>
                        import ("@/views/pages/login"),
                },
                {
                    path: "/register",
                    name: "page-payment",
                    component: () =>
                        import ("@/views/pages/register"),
                },
                {
                    path: "/register-faqs",
                    name: "register-faqs",
                    component: () =>
                        import ("@/views/pages/faq/index.vue"),
                },
                {
                    path: "/google-authentication-help",
                    name: "google-help",
                    component: () =>
                        import ("@/views/pages/googleAuthenticatorFAQ/index.vue"),
                },
                {
                    path: "/exchange",
                    name: "exchange",
                    component: () =>
                        import ("@/views/pages/exchange/index.vue"),
                },
                {
                    path: "/sync-ecs",
                    name: "sync",
                    component: () =>
                        import ("@/views/pages/sync/index.vue"),
                },
                {
                    path: "/pages/error-404",
                    name: "page-error-404",
                    component: () =>
                        import ("@/views/pages/Error404.vue"),
                },
                {
                    path: "/pages/error-500",
                    name: "page-error-500",
                    component: () =>
                        import ("@/views/pages/Error500.vue"),
                },
            ],
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/pages/error-404",
        },
    ],
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;