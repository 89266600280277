<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ connectedAccount }}</p>
    </div>

    <div class="con-img ml-3">
      <img
        key="onlineImg"
        :src="require('@/assets/images/user.png')"
        alt="user-img"
        width="40"
        height="40"
        class="rounded-full shadow-md cursor-pointer block"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
    }),

    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>
