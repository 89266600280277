/*=========================================================================================
  File Name: paymentGetters.js
  Description: Payment Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  invests(state) {
    return state.invests;
  },
  investMine(state) {
    return state.investMine;
  },
  benefits(state) {
    return state.benefits;
  },
  benefitsMine(state) {
    return state.benefitsMine;
  },
  benefitsNFT(state) {
    return state.benefitsNFT;
  },
  benefitsNFTMine(state) {
    return state.benefitsNFTMine;
  },
  withdraws(state) {
    return state.withdraws;
  },
  withdrawsMine(state) {
    return state.withdrawsMine;
  },
  availableBalance(state) {
    return state.availableBalance;
  },
  withdrawsNFT(state) {
    return state.withdrawsNFT;
  },
  withdrawsNFTMine(state) {
    return state.withdrawsNFTMine;
  },
  availableNFTBalance(state) {
    return state.availableNFTBalance;
  },
  burnReason(state) {
    return state.burnReason;
  },
  appsList(state) {
    return state.appsList;
  },
  investInfo(state) {
    return state.investInfo;
  },
};
