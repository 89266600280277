<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
      <div class="flex items-center">
        <div class="relative flex mr-4">
          <img
            :src="require('@/assets/images/user.png')"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md block"
          />
          <div
            class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0 bg-success"
          ></div>
        </div>
        <h6>{{ selectedTicket.title }}</h6>
      </div>
      <vs-spacer></vs-spacer>
      <vs-chip
        :color="getOrderStatusColor(selectedTicket.status)"
        class="product-status ml-auto"
      >
        {{ selectedTicket.status | title }}
      </vs-chip>
    </vs-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      selectedTicket: "ticket/selectedTicket",
    }),
  },
  methods: {
    getOrderStatusColor(status) {
      if (status === "progress") return "warning";
      if (status === "open") return "success";
      if (status === "close") return "danger";
      return "success";
    },
  },
};
</script>
