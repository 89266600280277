import Web3 from "web3";

const web3 = new Web3(window.ethereum);

import ABI from "../../contractABI.json";

export const callContractMixin = {
    methods: {
        async callContract(
            contractAddress,
            methodName,
            args,
            callback,
            changeState = false,
            value = null
        ) {
            try {
                console.log(value);
                let myContract = new web3.eth.Contract(ABI, contractAddress);

                const options = {
                    from: sessionStorage.getItem("connectedAccount"),
                    'gas': 300000,
                    "gasPrice": await web3.eth.getGasPrice(),
                };

                if (!changeState) {
                    await myContract.methods[methodName](...args).call((err, res) => {
                        if (res) {
                            callback && callback(res);
                        }
                    });
                } else {
                    await myContract.methods[methodName](...args).send(
                        options,
                        (err, res) => {
                            if (res) {
                                callback && callback(res);
                            }
                        }
                    );
                }
            } catch (error) {
                this.$vs.notify({
                    title: "Contract Error",
                    text: error.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger",
                    fixed: true,
                });

                if (error == "REVERT opcode executed") {
                    setTimeout(() => {
                        this.redirectWithDelay();
                    }, 3000);
                }
            }
        },

        fromSun(value) {
            return +value / 1000000;
        },

        toSun(value) {
            return +value * 1000000;
        },

        async getMaticBalance(walletAddress) {
            let result = await web3.eth.getBalance(walletAddress);
            result = web3.utils.fromWei(result, "ether");

            return result;
        },
        redirectWithDelay() {
            if (this.$route.name !== "page-payment") {
                this.$router.push("/register");
            }
        },
    },
};