// /*=========================================================================================
//   File Name: ticketActions.js
//   Description: Ticket Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/

import api from "../../axios";

export default {
    // Get Departments
    async getDepartments({ commit }) {
        try {
            const { data } = await api.get("tickets/departments");

            //
            let departments = data.result;
            //

            //
            commit("SET_DEPARTMENTS", departments);
            //

            return true;
        } catch (error) {
            return false;
        }
    },
    //

    // Get Ticket List
    async getTickets({ commit }, payload) {
        try {
            const { data } = await api.get("tickets", {
                params: payload,
            });

            //
            let tickets = data.result.list;
            let lastPage = data.result.totalPages;
            //

            //
            commit("SET_TICKETS", tickets);
            commit("SET_TICKET_LASTPAGE", lastPage);
            //

            return true;
        } catch (error) {
            return false;
        }
    },

    // Set New Ticket
    async setNewTicket({ commit }, payload) {
        try {
            const { data } = await api.post("tickets/new", payload);

            //
            let ticket = data.result;
            //

            //
            commit("SET_NEW_TICKET", ticket);
            //

            return true;
        } catch (error) {
            return false;
        }
    },

    // Show Specific Ticket
    async showTicket({ commit }, payload) {
        try {
            const { data } = await api.get(`tickets/view`, {
                params: {
                    id: payload,
                },
            });

            //
            let ticket = data.result;
            //

            //
            commit("SHOW_TICKET", ticket);
            //

            return true;
        } catch (error) {
            return false;
        }
    },
    //

    // Send Answer
    async sendReply({ commit }, payload) {
        try {
            const { data } = await api.post(
                `tickets/new-answer?id=${payload.id}`,
                payload.content
            );

            //
            let answer = data.result;
            //

            //
            commit("SHOW_TICKET", answer);
            //

            return true;
        } catch (error) {
            return false;
        }
    },
    //
};