<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card class="overflow-hidden">
    <div slot="no-body">
      <div
        class="p-6"
        :class="{
          'flex justify-between flex-row-reverse items-center': iconRight,
          'text-center': !iconRight && hideChart,
          'pb-0': !hideChart,
        }"
      >
        <template>
          <feather-icon
            v-if="withdrawBtn === false && withdrawNFT === false"
            :icon="icon"
            class="p-3 inline-flex rounded-full"
            :class="[
              `text-${color}`,
              { 'mb-4': !iconRight },
              { pointer: copyToClipboard },
            ]"
            :style="{ background: `rgba(var(--vs-${color}),.15)` }"
            @click="copy(statisticTitle)"
          ></feather-icon>

          <template v-else>
            <vs-button
              v-if="withdrawBtn"
              type="border"
              :color="color"
              icon-pack="feather"
              icon="icon-arrow-down-circle"
              @click="withdrowReq"
              :disabled="availableBalance == 0"
            >
              Withdraw Commissions
            </vs-button>

            <vs-button
              v-if="withdrawNFT"
              type="border"
              :color="color"
              icon-pack="feather"
              icon="icon-arrow-down-circle"
              @click="withdrowNFTReq"
              :disabled="availableNFTBalance == 0"
            >
              Claim Staking Rewards
            </vs-button>
          </template>
        </template>

        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ statistic }}</h2>
          <p v-if="statisticSubject">{{ statisticSubject }}</p>
          <span>{{ statisticTitle }}</span>
        </div>
      </div>

      <div class="line-area-chart" v-if="!hideChart">
        <vue-apex-charts
          ref="apexChart"
          :type="type"
          height="100"
          width="100%"
          :options="chartOptions"
          :series="chartData"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import chartConfigs from "./chartConfigs.js";
import _color from "@/assets/utils/color.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      required: true,
    },
    statisticTitle: {
      type: String,
    },
    chartData: {
      // type: Array,
      // required: true
    },
    color: {
      type: String,
      default: "primary",
    },
    colorTo: {
      type: String,
    },
    // chartType: {
    //     type: String,
    //     default: 'line-chart',
    // },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    hideChart: {
      type: Boolean,
      default: false,
    },
    statisticSubject: {
      type: String,
    },
    copyToClipboard: {
      type: Boolean,
      default: false,
    },
    withdrawBtn: {
      type: Boolean,
      default: false,
    },
    withdrawNFT: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex(this.color) } },
      });
    },
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      availableBalance: "modulePayment/availableBalance",
      availableNFTBalance: "modulePayment/availableNFTBalance",
    }),
    //
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getWithdraws: "modulePayment/getWithdraws",
      getNFTWithdraws: "modulePayment/getNFTWithdraws",
      withdrow: "modulePayment/withdrow",
      withdrowNFT: "modulePayment/withdrowNFT",
    }),
    //

    getHex(color) {
      if (_color.isColor(color)) {
        let rgb = window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(`--vs-${color}`);
        rgb = rgb.split(",");
        return `#${(
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)}`;
      }
      return color;
    },
    gradientToColor(color) {
      const gradientToColors = {
        primary: "#A9A2F6",
        success: "#55DD92",
        warning: "#ffc085",
        danger: "#F97794",
      };

      return gradientToColors[color]
        ? gradientToColors[color]
        : gradientToColors["primary"];
    },

    // Copy To Clipboard
    copy(val) {
      if (this.copyToClipboard) {
        let tempInput = document.createElement("input");
        tempInput.value = val;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        this.$vs.notify({
          title: "Copy To Clipboard",
          text: "Link Copied To Clipboard",
          iconPack: "feather",
          icon: "icon-copy",
          color: "success",
          position: "top-right",
        });
        document.body.removeChild(tempInput);
      }
    },
    //

    // Withdrow
    async withdrowReq() {
      // Start Loading
      this.$vs.loading();
      //
      await this.withdrow().then((res) => {
        if (res) {
          this.$vs.notify({
            title: "Withdrow",
            text: "Withdrow Request Send",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
          // Close Loading
          this.$vs.loading.close();
          //
          // Get Withdraw List
          this.getWithdraws();
        } else {
          // Close Loading
          this.$vs.loading.close();
          //

          // Get Withdraw List
          this.getWithdraws();
        }
      });
    },
    //

    // Withdrow
    async withdrowNFTReq() {
      // Start Loading
      this.$vs.loading();
      //
      await this.withdrowNFT().then((res) => {
        if (res) {
          this.$vs.notify({
            title: "NFT Withdrow",
            text: "Withdrow Request Send",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
          // Close Loading
          this.$vs.loading.close();
          //
          // Get Withdraw List
          this.getNFTWithdraws();
        } else {
          // Close Loading
          this.$vs.loading.close();
          //

          // Get Withdraw List
          this.getNFTWithdraws();
        }
      });
    },
    //
  },
  components: {
    VueApexCharts,
  },
  created() {
    if (this.type === "area") {
      // assign chart options
      this.chartOptions = Object.assign({}, chartConfigs.areaChartOptions);

      this.chartOptions["theme"] = {
        monochrome: {
          enabled: true,
          color: this.getHex(this.color),
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
    } else if (this.type === "line") {
      // Assign chart options
      this.chartOptions = JSON.parse(
        JSON.stringify(chartConfigs.lineChartOptions)
      );

      this.chartOptions.fill.gradient.gradientToColors = [
        this.gradientToColor(this.colorTo || this.color),
      ];
      this.chartOptions.colors = [this.getHex(this.color)];
    }
  },
};
</script>
