<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-1/4 lg:w-1/4 mb-base ecs">
      <NFTPool />
    </div>
    <div class="vx-col w-full md:w-3/4 lg:w-3/4">
      <p
        class="not-data-table vs-table--not-data w-full"
        v-if="!nftList.length"
      >
        No NFT Available
      </p>
      <div
        class="items-list-view mb-base"
        v-for="item in nftList"
        :key="item.id"
      >
        <ListItem :item="item" @removeFromList="removeFromList" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ListItem from "../../../components/nfts/ListItem.vue";
import NFTPool from "../../../components/nfts/NFTPool.vue";
export default {
  components: { ListItem, NFTPool },
  data() {
    return {
      nftList: [],
      loading: false,
    };
  },

  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
    }),
    //
  },

  methods: {
    ...mapActions({
      getFreezedNFTs: "modulePayment/getFreezedNFTs",
      getDashboardData: "auth/getDashboardData",
    }),

    removeFromList(id) {
      this.nftList = this.nftList.filter((item) => item.id != id);
    },

    async get_freezed_nfts() {
      //
      await this.getFreezedNFTs().then((res) => {
        if (res) {
          this.nftList = res;
        }
      });
    },
  },

  async mounted() {
    //
    this.$vs.loading();

    //
    await this.getDashboardData();
    await this.get_freezed_nfts();

    //
    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
@import "./nfts.scss";
</style>
