import axios from "axios";
import store from "@/store";

// create an axios instance
const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL: 'http://flight2.local.com/'
});

// request interceptor
api.interceptors.request.use(
    (config) => {
        // do something before request is sent

        if (store.getters["auth/token"]) {
            config.headers.token = store.getters["auth/token"];
        }
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
api.interceptors.response.use(
    (response) => {
        if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202 ||
            response.status === 203 ||
            response.status === 204 ||
            response.status === 205 ||
            response.status === 206
        ) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        store.dispatch("notification/showNotification");
        store.dispatch("notification/setNotification", {
            title: error.response.data.code,
            message: error.response.data.message,
        });
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    window.location = "/#/login";
                    store.commit("LOGOUT");
                    break;

                case 421:
                    window.location = "/#/register";
                    break;

                case 404:
                    window.location = "/#/pages/error-404";
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);

export default api;