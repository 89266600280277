<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-register"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="require('@/assets/images/pages/ancestry-login.jpg')"
                alt="login"
                class="mx-auto authorize-logo"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="p-8 register-tabs-container">
                <div class="vx-card__title mb-8">
                  <h4>Register</h4>
                </div>

                <template v-if="haveLink">
                  <span v-if="!metaMaskInstalled">
                    Please Install Meta Mask From
                    <a :href="metaMaskInstallAddress">Here</a>.
                  </span>
                  <span v-if="metaMaskInstalled && !connectedAccount">
                    Please Login into your Meta Mask account!
                  </span>

                  <section v-if="connectedAccount">
                    <div>
                      <div class="vs-row mb-3 mt-3" v-if="connectedAccount">
                        <h6>Connected Wallet :</h6>
                        <p class="wallet-address">
                          {{ connectedAccount }}
                        </p>
                      </div>

                      <div class="vs-row mb-3">
                        <h6>Referral :</h6>
                        <p class="wallet-address">
                          {{ referral }}
                        </p>
                      </div>
                    </div>

                    <img
                      :src="require(`@/assets/images/register.png`)"
                      alt="register"
                    />

                    <div class="vx-row">
                      <div class="vx-col w-full register-btn">
                        <vs-button
                          class="w-full"
                          @click="registerPrompt = true"
                        >
                          Register
                        </vs-button>
                      </div>

                      <vs-prompt
                        class="register-prompt"
                        title="Register"
                        accept-text="Confirm"
                        @accept="registerUser"
                        :active.sync="registerPrompt"
                      >
                        <div class="con-exemple-prompt">
                          <h6 class="text-danger inline-block">
                            IMPORTANT NOTE
                          </h6>
                          <span
                            >: You have been referred from this
                            <h6 class="inline-block break-all">
                              {{ referral }}
                            </h6>
                            wallet please confirm it</span
                          >
                        </div>
                      </vs-prompt>
                    </div>
                  </section>
                </template>

                <template v-else>
                  <div class="vx-row social-holder">
                    <div class="vx-col w-full text-center mb-10">
                      Have Not Link
                    </div>
                    <div class="vx-col w-full social">
                      <a
                        :href="item.link"
                        target="_blank"
                        v-for="(item, index) in socials"
                        :key="index"
                      >
                        <img
                          :src="
                            require(`@/assets/images/social/${item.name}.png`)
                          "
                          :alt="item.name"
                        />
                      </a>
                    </div>
                  </div>
                </template>
                <vs-divider color="primary" class="faq-divider">
                  <router-link :to="{ name: 'register-faqs' }">
                    FAQs
                  </router-link>
                </vs-divider>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      registerPrompt: false,

      haveLink: false,

      //
      referral: "",
      side: "",

      //
      metaMaskInstallAddress:
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",

      //
      ECS: {
        amount: "",
        inputDisable: true,
        paid: true,
      },

      //
      USDT: {
        amount: "",
        inputDisable: true,
        paid: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      metaMaskInstalled: "metaMask/metaMaskInstalled",
      connectedAccount: "metaMask/connectedAccount",
      metaMaskObject: "metaMask/metaMaskObject",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      socials: "auth/socials",
      //
      dashboard: "auth/dashboard",
      //
      user: "auth/user",
    }),
  },

  methods: {
    // Vuex Map Actions
    ...mapActions({
      pay: "modulePayment/pay",
      getToken: "auth/getToken",
      getBaseInfo: "contract/getBaseInfo",
      getDashboardData: "auth/getDashboardData",
      register: "auth/register",
      checkUserExist: "auth/checkUserExist",
    }),
    //

    // Vuex Map Mutations
    ...mapMutations({
      setApprovedAmounts: "modulePayment/setApprovedAmounts",
    }),
    //

    // Redirect To Dashboard
    redirectLogin() {
      this.$vs.notify({
        title: "You Are Registered",
        text: "Redirecting To Login Page",
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });

      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 2000);
    },
    //

    // Get Params From Query
    getQueryParams() {
      if (this.$route.fullPath === "/register") {
        this.haveLink = false;
      } else if (!this.$route.query) {
        this.haveLink = false;
      } else if (this.$route.query) {
        if (
          !this.$route.query.referral_code ||
          this.$route.query.referral_code === "undefined" ||
          !this.$route.query.side ||
          this.$route.query.side === "undefined"
        ) {
          this.haveLink = false;
        } else {
          this.haveLink = true;
          this.referral = this.$route.query.referral_code;
          this.side = this.$route.query.side;

          // check referral wallet address
          const walletCheck = /^0x[a-fA-F0-9]{40}$/.test(this.referral);
          if (!walletCheck) {
            this.$vs.notify({
              title: "Referral",
              text: "Your referral address does not work in this version",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });

            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 2000);
          }
        }
      }
    },
    //

    // Pay And Register New User
    async registerUser() {
      let content = {
        parent: this.referral,
        side: this.side === "left" ? 0 : 1,
        wallet: this.connectedAccount,
      };

      this.register(content).then((res) => {
        if (res) {
          this.redirectLogin();
        }
      });
    },
    //

    // Check User Exists
    check_user_exist() {
      if (this.connectedAccount) {
        this.checkUserExist(this.connectedAccount).then((res) => {
          if (res) {
            this.redirectLogin();
          }
        });
      }
    },
  },
  async mounted() {
    this.check_user_exist();

    // Get Params From Query
    this.getQueryParams();
    //
  },
  watch: {
    connectedAccount() {
      this.check_user_exist();
    },
    $route() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
@import "./register.scss";
</style>
