var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"overflow-hidden"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"p-6",class:{
        'flex justify-between flex-row-reverse items-center': _vm.iconRight,
        'text-center': !_vm.iconRight && _vm.hideChart,
        'pb-0': !_vm.hideChart,
      }},[[(_vm.withdrawBtn === false && _vm.withdrawNFT === false)?_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[
            ("text-" + _vm.color),
            { 'mb-4': !_vm.iconRight },
            { pointer: _vm.copyToClipboard } ],style:({ background: ("rgba(var(--vs-" + _vm.color + "),.15)") }),attrs:{"icon":_vm.icon},on:{"click":function($event){return _vm.copy(_vm.statisticTitle)}}}):[(_vm.withdrawBtn)?_c('vs-button',{attrs:{"type":"border","color":_vm.color,"icon-pack":"feather","icon":"icon-arrow-down-circle","disabled":_vm.availableBalance == 0},on:{"click":_vm.withdrowReq}},[_vm._v(" Withdraw Commissions ")]):_vm._e(),(_vm.withdrawNFT)?_c('vs-button',{attrs:{"type":"border","color":_vm.color,"icon-pack":"feather","icon":"icon-arrow-down-circle","disabled":_vm.availableNFTBalance == 0},on:{"click":_vm.withdrowNFTReq}},[_vm._v(" Claim Staking Rewards ")]):_vm._e()]],_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold"},[_vm._v(_vm._s(_vm.statistic))]),(_vm.statisticSubject)?_c('p',[_vm._v(_vm._s(_vm.statisticSubject))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.statisticTitle))])])],2),(!_vm.hideChart)?_c('div',{staticClass:"line-area-chart"},[_c('vue-apex-charts',{ref:"apexChart",attrs:{"type":_vm.type,"height":"100","width":"100%","options":_vm.chartOptions,"series":_vm.chartData}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }