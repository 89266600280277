<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex">
    <div
      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
      @click.stop="findNftSidebar = !findNftSidebar"
    >
      <span class="text-base text-primary">Can't find NFT ?</span>
    </div>

    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="findNftSidebar"
    >
      <!-- <div class="outer" :class="isSidebarActive ? 'd-block' : 'd-none'"></div> -->
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>Find NFT</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="findNftSidebar = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div class="p-6">
          <!-- Select -->
          <div class="vx-row custom-select">
            <div class="vx-col w-full">
              <label for="reason">App</label>
              <select id="reason" v-model="app">
                <option
                  v-for="(item, index) in apps"
                  :key="index"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- NFT ID -->
          <vs-input
            label="NFT ID"
            v-model="nftID"
            class="mt-5 w-full"
            name="NFT ID"
            type="number"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('NFT ID')">
            {{ errors.first("NFT ID") }}
          </span>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button
          id="find-nft-loading"
          class="mr-6 vs-con-loading__container"
          @click="findNFT"
          :disabled="!nftID"
        >
          Check
        </vs-button>
        <vs-button
          type="border"
          color="danger"
          @click.stop="findNftSidebar = false"
        >
          Cancel
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { callContractMixin } from "@/mixins/callCotractMixin";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      findNftSidebar: false,
      nftLoading: false,

      nftID: "",
      app: "",

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      connectedAccount: "metaMask/connectedAccount",
      //
      contractInfo: "contract/contractInfo",
    }),

    apps() {
      return this.contractInfo.availableNfts;
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    findNFT() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.nftLoading = true;
          this.$vs.loading({
            container: "#find-nft-loading",
            scale: 0.45,
          });

          await this.callContract(
            this.app.address,
            "ownerOf",
            [this.nftID],
            async (res) => {
              if (res) {
                if (res.toLowerCase() === this.connectedAccount.toLowerCase()) {
                  this.$emit("addNFT", this.app, this.nftID);
                  this.findNftSidebar = false;
                } else {
                  this.$vs.notify({
                    title: "NFT Owner",
                    text: "You do not own this NFT",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger",
                    fixed: true,
                  });
                }
              }
            },
            false //read false & write true
          );

          //
          this.nftLoading = false;
          this.$vs.loading.close("#find-nft-loading > .con-vs-loading");
        }
      });
    },
  },
  watch: {
    findNftSidebar() {
      if (this.findNftSidebar) {
        this.app = this.apps[0];
      }
    },
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.img-preview {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>
