// /*=========================================================================================
//   File Name: paymentActions.js
//   Description: Payment Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: Daniyal
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/

import api from "../../axios";

export default {
  // Pay
  async pay(_, payload) {
    try {
      await api.post("invests/pay", payload);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Burn
  async burn(_, payload) {
    try {
      await api.post("invests/burn", payload);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Freeze
  async freeze(_, payload) {
    try {
      await api.post("invests/freeze", payload);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Invest List
  async getInvests({ commit }) {
    try {
      const { data } = await api.get("invests/index");

      //
      const { list, mine } = data.result;
      //

      //
      commit("setInvests", list);
      commit("setInvestMine", mine);
      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Benefits
  async getBenefits({ commit }) {
    try {
      const { data } = await api.get("invests/benefits");

      //
      const { list, mine } = data.result;
      //

      //
      commit("setBenefits", list);
      commit("setBenefitsMine", mine);
      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Staking Rewards
  async getStakingBenefits({ commit }) {
    try {
      const { data } = await api.get("invests/benefits-nft");

      //
      const { list, mine } = data.result;
      //

      //
      commit("setBenefitsNFT", list);
      commit("setBenefitsNFTMine", mine);
      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Benefits
  async getWithdraws({ commit }) {
    try {
      const { data } = await api.get("withdraws/index");

      //
      const { list, mine, availableBalance } = data.result;
      //

      //
      commit("setWithdraws", list);
      commit("setWithdrawsMine", mine);
      commit("setAvailableBalance", availableBalance);
      //

      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get NFT Benefits
  async getNFTWithdraws({ commit }) {
    try {
      const { data } = await api.get("withdraws/index-nft");

      //
      const { list, mine, availableBalance } = data.result;
      //

      //
      commit("setWithdrawsNFT", list);
      commit("setWithdrawsNFTMine", mine);
      commit("setAvailableNFTBalance", availableBalance);
      //

      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Withdrow Request
  async withdrow({ commit }) {
    try {
      await api.post("withdraws/withdraw-all");

      //
      commit("");
      return true;
      //
    } catch (error) {
      return false;
    }
  },

  // Withdrow Request
  async withdrowNFT({ commit }) {
    try {
      await api.post("withdraws/withdraw-nft-pool");

      //
      commit("");
      return true;
      //
    } catch (error) {
      return false;
    }
  },

  // Unfreez Request
  async unFreez(_, payload) {
    try {
      await api.post("withdraws/unfreeze", payload);

      //
      return true;
      //
    } catch (error) {
      return false;
    }
  },

  // Get Invest Info
  async getInvestInfo({ commit }, payload) {
    try {
      const { data } = await api.post("site/get-invest-info", payload);

      //
      let info = data.result;

      //
      commit("setInvestInfo", info);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Freeze NFT
  async freezeNFT(_, payload) {
    try {
      await api.get("nfts/freeze", {
        params: payload,
      });

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Freezed NFTs
  async getFreezedNFTs({ commit }) {
    try {
      const { data } = await api.get("nfts/index");

      //
      commit("");

      //
      let list = data.result.list;

      //
      return list;
    } catch (error) {
      return false;
    }
  },
  //

  // UnFreeze NFTs
  async unfreezNFT(_, payload) {
    try {
      await api.get(`nfts/unfreeze?id=${payload}`);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  //

  // Get Apps List
  async getAppsList({ commit }, payload) {
    try {
      const { data } = await api.get("site/apps", payload);

      //
      let info = data.result;

      //
      commit("setAppsList", info);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
};
