<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  content: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div
      class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
      @click.stop="newTicketSidebar = !newTicketSidebar"
    >
      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
      <span class="ml-2 text-base text-primary">Add New</span>
    </div>

    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="newTicketSidebar"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>ADD NEW TICKET</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="newTicketSidebar = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div class="p-6">
          <!-- Title -->
          <vs-input
            label="Title"
            v-model="newTicket.title"
            class="mt-5 w-full"
            name="title"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('title')">
            {{ errors.first("title") }}
          </span>
          <!-- Reason -->
          <div class="vx-row custom-select">
            <div class="vx-col mt-5 w-full">
              <label class="vs-input--label" for="reason">Reason</label>
              <select id="reason" v-model="newTicket.reason">
                <option
                  v-for="(item, index) in resons"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('item-reason')">
            {{ errors.first("item-reason") }}
          </span>

          <template v-if="newTicket.reason === 'Migration'">
            <!-- Tron Wallet -->
            <vs-input
              label="Tron Address"
              v-model="newTicket.tronWallet"
              class="mt-5 w-full"
              name="tron address"
              v-validate="{ required: true, regex: /^T[a-zA-Z0-9]{33}$/ }"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('tron address')"
            >
              {{ errors.first("tron address") }}
            </span>

            <!-- Poligon Wallet -->
            <vs-input
              label="Poligon Address"
              v-model="newTicket.poligonWallet"
              class="mt-5 w-full"
              name="poligon address"
              v-validate="{ required: true, regex: /^0x[a-fA-F0-9]{40}$/ }"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('poligon address')"
            >
              {{ errors.first("poligon address") }}
            </span>
          </template>

          <!-- Department -->
          <div class="vx-row custom-select">
            <div class="vx-col mt-5 w-full">
              <label class="vs-input--label" for="departments">
                Departments
              </label>
              <select
                id="departments"
                name="departments"
                v-model="newTicket.department"
              >
                <option
                  v-for="(item, index) in departments"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <span class="text-danger text-sm" v-show="errors.has('department')">
            {{ errors.first("department") }}
          </span>

          <!-- content -->
          <div class="mt-5">
            <label for="message" class="vs-input--label">Message</label>
            <vs-textarea
              id="message"
              name="message"
              v-model="newTicket.message"
              height="200px"
            />
          </div>

          <!-- Upload -->
          <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

          <!-- <div class="upload-img mt-5">
            <input
              type="file"
              class="hidden"
              ref="uploadInput"
              @change="addAtachment"
              multiple
              accept="image/*, .pdf, .zip, .rar"
            />
            <vs-button @click="$refs.uploadInput.click()">
              Upload file
            </vs-button>
          </div> -->

          <div v-if="newTicket.attachments.length" class="vx-row">
            <div
              v-for="(file, index) in newTicket.attachments"
              :key="index"
              class="vx-col mt-4 w-1/2"
            >
              <div class="file-holder">
                <!-- Delete Selected File -->
                <div class="delete-box">
                  <feather-icon
                    class="pointer"
                    icon="XCircleIcon"
                    @click="deleteFile(index)"
                  ></feather-icon>
                </div>
                <!-- if uploaded file is image -->
                <div
                  class="img-holder"
                  v-if="
                    file.type === 'image/jpg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/jpeg'
                  "
                >
                  <!-- if File is img -->
                  <img
                    class="img-preview"
                    :src="showImage(file)"
                    alt="your image"
                  />
                </div>

                <!-- when uploaded file is not image -->
                <div class="show-uploaded-file" v-else>
                  <!-- if file is pdf -->
                  <template v-if="file.type === 'application/pdf'">
                    <img
                      :src="require(`@/assets/images/pdf.png`)"
                      alt="pdf file"
                    />
                    <span>{{ file.name }}</span>
                  </template>

                  <!-- if file is zip -->
                  <template v-if="file.type === 'application/x-zip-compressed'">
                    <img
                      :src="require(`@/assets/images/rar and zip-2.png`)"
                      alt="compresed file"
                    />
                    <span>{{ file.name }}</span>
                  </template>

                  <!-- if file is rar -->
                  <template v-if="file.type === ''">
                    <img
                      :src="require(`@/assets/images/rar and zip-2.png`)"
                      alt="compresed file"
                    />
                    <span>{{ file.name }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button
          id="submit-loading"
          class="mr-6 vs-con-loading__container"
          @click="submitData"
          :disabled="!isFormValid || loading"
        >
          Submit
        </vs-button>
        <vs-button
          type="border"
          color="danger"
          @click.stop="newTicketSidebar = false"
        >
          Cancel
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      newTicketSidebar: false,

      loading: false,

      newTicket: {
        reason: "Migration",
        title: "",
        message: "",
        attachments: [],
        department: "",
        tronWallet: "",
        poligonWallet: "",
      },

      resons: [
        "Migration",
        "Deposite usdt",
        "Withdraw usdt",
        "2fa reset",
        "Withdraw nft",
        "Freeze token",
        "Unfreeze token",
        "other",
      ],

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      departments: "ticket/departments",
    }),
    //
    isFormValid() {
      if (this.newTicket.reason === "Migration")
        return (
          !this.errors.any() &&
          this.newTicket.title &&
          this.newTicket.message &&
          this.newTicket.reason &&
          this.newTicket.tronWallet &&
          this.newTicket.poligonWallet &&
          this.newTicket.department
        );

      return (
        !this.errors.any() &&
        this.newTicket.title &&
        this.newTicket.message &&
        this.newTicket.department
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    ...mapActions({
      setNewTicket: "ticket/setNewTicket",
    }),

    showImage(file) {
      return URL.createObjectURL(file);
    },

    deleteFile(index) {
      let files = [...this.newTicket.attachments];
      files.splice(index, 1);
      this.newTicket.attachments = files;
    },

    initValues() {
      this.newTicket = {
        reason: "Migration",
        title: "",
        message: "",
        attachments: [],
        department: "",
        tronWallet: "",
        poligonWallet: "",
      };
    },
    submitData() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.loading = true;
          this.$vs.loading({
            container: "#submit-loading",
            scale: 0.45,
          });

          const {
            reason,
            title,
            message,
            department,
            attachments,
            poligonWallet,
            tronWallet,
          } = this.newTicket;

          let messageString =
            reason === "Migration"
              ? `Reason : ${reason} / Poligon Wallet : ${poligonWallet} / Tron Wallet : ${tronWallet} / Message : ${message}`
              : `Reason: ${reason} / Message: ${message}`;

          //
          const fd = new FormData();

          fd.append("title", title);
          fd.append("message", messageString);
          fd.append("department", department);
          if (attachments.length) {
            for (let index = 0; index < attachments.length; index++) {
              const file = attachments[index];
              fd.append("files[]", file);
            }
          }

          //
          await this.setNewTicket(fd).then((res) => {
            if (res) {
              this.newTicketSidebar = false;
              this.initValues();
            }
          });

          this.loading = false;
          this.$vs.loading.close("#submit-loading > .con-vs-loading");
        }
      });
    },
    addAtachment(e) {
      this.newTicket.attachments = e.target.files;

      // if (file.size <= 2048000.0000000002) {
      //   //
      //   this.newTicket.attachments = file;
      //   this.fileType = file.type;
      //   //
      //   if (
      //     file.type === "image/jpeg" ||
      //     file.type === "image/jpg" ||
      //     file.type === "image/png"
      //   ) {
      //     this.url = URL.createObjectURL(file);
      //   }
      //   //
      // } else {
      //   // Size Error
      //   this.$vs.notify({
      //     title: "Large Size",
      //     text: "Your file is larger than 2mb",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //     position: "top-right",
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
