/*=========================================================================================
  File Name: tronBotGetters.js
  Description: Tron Bot Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Daniyal
==========================================================================================*/

export default {
    metaMaskInstalled: state => state.metaMaskInstalled,
    connectedAccount: state => state.connectedAccount,
};