<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ArrowDownCircleIcon"
          icon-right
          :statistic="withdrawsNFTMine"
          statisticTitle="Withdraws"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ArrowDownCircleIcon"
          icon-right
          :statistic="`${contractInfo.withdrawUsdtFeePercent}%`"
          statisticTitle="Withdraw Fee"
          color="danger"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <statistics-card-line
          hideChart
          withdrawNFT
          class="mb-base"
          icon="ArrowDownCircleIcon"
          icon-right
          :statistic="availableNFTBalance"
          statisticTitle="Available NFT Balance"
          statisticFee="sdkcsdmck"
        />
      </div>
    </div>
    <vs-table ref="table" :data="withdrawsNFT">
      <template slot="thead">
        <vs-th sort-key="nft_id">nft</vs-th>
        <vs-th sort-key="usdt">usdt</vs-th>
        <vs-th sort-key="status">status</vs-th>
        <vs-th sort-key="created_at">created at</vs-th>
        <vs-th sort-key="tax_id">Tax ID</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="nft font-medium">{{ tr.nft_id ? tr.nft_id : "-" }}</p>
            </vs-td>

            <vs-td>
              <p class="usdt font-medium">{{ tr.usdt | floor }}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="statusColor(tr.status)">{{ tr.status }}</vs-chip>
            </vs-td>

            <vs-td>
              <p class="font-medium">
                {{ tr.created_at | moment("MMMM Do YYYY") }}
              </p>
            </vs-td>

            <vs-td class="tax_id">
              <vx-tooltip :text="tr.tax_id" position="bottom" v-if="tr.tax_id">
                <p class="tax_id font-medium truncate">
                  {{ tr.tax_id }}
                </p>
              </vx-tooltip>
              <p v-else>Not Paid Yet</p>
            </vs-td>

            <vs-td class="copy">
              <feather-icon
                icon="CopyIcon"
                class="text-warning pointer"
                @click="copy(tr.tax_id)"
              ></feather-icon>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <vs-pagination :total="40" v-model="page"></vs-pagination> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import { callContractMixin } from "@/mixins/callCotractMixin";
export default {
  components: { StatisticsCardLine },
  data() {
    return {
      page: 4,
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      withdrawsNFT: "modulePayment/withdrawsNFT",
      withdrawsNFTMine: "modulePayment/withdrawsNFTMine",
      availableNFTBalance: "modulePayment/availableNFTBalance",
      //
      contractInfo: "contract/contractInfo",
    }),
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getNFTWithdraws: "modulePayment/getNFTWithdraws",
    }),
    //

    // Status Color
    statusColor(type) {
      if (type === "paid") return "success";
      if (type === "pending") return "warning";
      if (type === "failed") return "danger";
    },
    //

    // Copy To Clipboard
    copy(val) {
      let tempInput = document.createElement("input");
      tempInput.value = val;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      this.$vs.notify({
        title: "Copy To Clipboard",
        text: "Address Copied To Clipboard",
        iconPack: "feather",
        icon: "icon-copy",
        color: "success",
        position: "top-right",
      });
      document.body.removeChild(tempInput);
    },
    //
  },
  async mounted() {
    // Start Loading
    this.$vs.loading();
    await this.getBaseInfo();
    //
    await this.getNFTWithdraws();
    // Close Loading
    this.$vs.loading.close();
    //
  },
  mixins: [callContractMixin],
};
</script>

<style lang="scss">
@import "./widthraw.scss";
</style>
